import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function MobileEmbedTabContent() {
  return (
    <div class="tab-pane fade" id="leftTab4" role="tabpanel" aria-labelledby="leftTab4-tab">
        <pre><p>Sample text here...</p><p>And another line of sample text here...</p></pre>
    </div>
  );
}

export default MobileEmbedTabContent;
