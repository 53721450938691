import React, { Component } from "react";
import { API_SERVER } from '../../constants/env';
class ResetPwd extends Component {
    constructor(props){
        super(props);
        this.handleResetLink = this.handleResetLink.bind(this);
    }
    async handleResetLink (e) {
        e.preventDefault();
        const { headerEvents } = this.props;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token');
        const pwd = document.getElementById('reset-modal-pwd').value;
        const confirmPwd = document.getElementById('reset-modal-confirm-pwd').value;
        const server = `${API_SERVER}/auth/reset/password`;
        const formData = new FormData();
        formData.append("pwd",  pwd);
        formData.append("token", token);
        if (pwd != confirmPwd) {
            headerEvents("passwordMisMatch", 'Password and confirm password are not same.');
        } else {
            console.log(server);
            document.body.style.cursor = 'wait';
            const result = await fetch(server, {
                method: "POST",
                credentials: 'include',
                body: formData,
                //headers: headers
            });
            document.body.style.cursor = 'default';
            const data = await result.json();
            if (data.success) {
                headerEvents("reset", data.message);
            } else {
                headerEvents("resetErr", data.message);
            }
            return false;
        }
    }
    resetPwd() {
        const {assistant,headerEvents } = this.props;
        return (
            <div class="modal fade login-modal" id="resetLinkModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="resetLinkModal" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header border-0">
                            
                        </div>
                        <div class="modal-body px-4 pb-5">
                            <h5 class="modal-title text-center" id="exampleModalLabel">Reset your password</h5>
                            <div class="modal-form-box pt-4">
                                <form onSubmit = { this.handleResetLink } >
                                    {
                                        (assistant.password_message !='') ?
                                            <div class="alert alert-danger" role="alert">
                                            {assistant.password_message}
                                            </div>
                                            :
                                            ""
                                    } 
                                    {
                                        (assistant.reset_message !='') ?
                                            <div class="alert alert-danger" role="alert">
                                            {assistant.reset_message}
                                            </div>
                                            :
                                            ""
                                    }
                                    {
                                        (assistant.reset_message_success !='') ?
                                            <div class="alert alert-success" role="alert">
                                            {assistant.reset_message_success}
                                            </div>
                                            :
                                            ""
                                    }
                                    <div class="mb-3">
                                        <input type="password" minLength='8' class="form-control" placeholder="password" id="reset-modal-pwd" required/>
                                    </div>
                                    <div class="mb-3">
                                            <input type="password" minLength='8' class="form-control" placeholder="confirm password" id="reset-modal-confirm-pwd" required/>
                                    </div>
                                    <button  type="submit" class="btn btn-primary w-100">Reset Password</button>
                                    <div class="mb-3 d-flex w-100 reset-pwd-box">
                                        <a href="/">Back to login</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return this.resetPwd(this.props);
    }
}

export default ResetPwd;