import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class MobilePreviewTabContent extends Component {
    mobilePreviewTabContent(props) {
        const { assistant } = props;
        const disableInput = !assistant.disablePreviewTab;
        return (
          <div class="tab-pane fade h-100" id="leftTab3" role="tabpanel" aria-labelledby="leftTab3-tab">
              <div class="create-chat-box h-100">
                  <div class="chat-list-box">
                        { (!assistant.disablePreviewTab) ? 
                            <div class="container-fluid full-height">
                                <div class="row full-height">
                                    <div class="col-12 icon-container">
                                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="h-2/3 w-2/3 text-gray-400" height="64px" width="64px" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                        <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                        <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                    </svg>

                                    </div>
                                </div>
                            </div>
                            :
                            <div class="chat-list-box-wrap">
                                <div class="right-image-box">
                                    <img src="/images/copilot-large.png" class="logo" alt="paper image" width="41" height="auto"/>
                                </div>
                                <div class="right-chat-body-box">
                                    <div class="chat-text-box">
                                        <div class="title"><strong>Inbound GPT Builder</strong></div>
                                        <div class="chat-description">
                                            <p>Sure, here's an example of HTML code using Bootstrap to create a layout with two columns, each containing two tabs at the top, center-aligned</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                  </div>
                  <div class="send-chat-box">
                      <div class="chat-box-bottom">
                          <div class="chat-box-wrap">
                              <div class="input-group mb-3">
                                  <input type="file" class="form-control" id="inputGroupFile02" style={{display: "none"}}/>
                                  <label class="input-group-text clip-icon-box" for="inputGroupFile02"><img src="/images/paper.png" class="paper-img" alt="paper image" width="20" height="auto"/></label>
                                  <textarea disabled={disableInput} class="form-control" placeholder="Message Inbound GPT" id="exampleFormControlTextarea1" rows="3"></textarea>
                                  <button disabled={disableInput} class="text-white p-0.5 border border-black rounded-lg transition-colors" data-testid="send-button"><span class="" data-state="closed">
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="text-white dark:text-black"><path d="M7 11L12 6L17 11M12 18V7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        );
    }
    render(){
        return this.mobilePreviewTabContent(this.props);
    }
}

export default MobilePreviewTabContent;
