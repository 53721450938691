import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LeftTabslist from "./left_tabs_list";
import RightTabslist from "./right_tabs_list";

class CopilotMainSection extends Component { 
  
  createCopilotMainSection(props) {
    const { message, assistant,builderTabEvents,configureTabEvents,headerEvents,previewTabEvents,embedTabEvents } = props;
    return (
      <div class="copilot-main-section h-100">
        <div class="wrapper h-100">
          <div class="row h-100">
            <LeftTabslist
              message = { message}
              assistant= {assistant} 
              builderTabEvents = {builderTabEvents}
              configureTabEvents = {configureTabEvents}
              headerEvents = {headerEvents}
              previewTabEvents = {previewTabEvents}
              embedTabEvents = {embedTabEvents}
            />
            <RightTabslist
              message = { message}
              assistant= {assistant} 
              builderTabEvents = {builderTabEvents}
              configureTabEvents = {configureTabEvents}
              headerEvents = {headerEvents}
              previewTabEvents = {previewTabEvents}
              embedTabEvents = {embedTabEvents}
            />
            </div>
        </div>
      </div>
    );
  }

  render() {
    return this.createCopilotMainSection(this.props);
  }
}

export default CopilotMainSection;
