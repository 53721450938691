import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from "./header";
import CopilotMainSection from "./copilot_main_section";
class RightBox extends Component { 
  
  createRightBox(props) {
    const { message, assistant,builderTabEvents,configureTabEvents,headerEvents,previewTabEvents,embedTabEvents } = props;
    let cls = 'col-md-10 col-sm-12 border-start border-top right-AI-box h-100';
    if (assistant.fullWidth) cls = 'col-md-10 col-sm-12 border-start border-top right-AI-box right-AI-box-w-collspse h-100';
    return (
      <div class={cls}>
        <Header
          message = { message}
          assistant= {assistant} 
          builderTabEvents = {builderTabEvents}
          configureTabEvents = {configureTabEvents}
          headerEvents = {headerEvents}
          previewTabEvents = {previewTabEvents}
          embedTabEvents = {embedTabEvents}
        />
        <CopilotMainSection
          message = { message}
          assistant= {assistant} 
          builderTabEvents = {builderTabEvents}
          configureTabEvents = {configureTabEvents}
          headerEvents = {headerEvents}
          previewTabEvents = {previewTabEvents}
          embedTabEvents = {embedTabEvents}
        />
      </div>
    );
  }

  render() {
    return this.createRightBox(this.props);
  }
}
export default RightBox;
