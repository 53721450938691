import React, { Component } from "react";
import GoogleloginBtn from '../google_login';
import { API_SERVER } from '../../constants/env';
class Signup extends Component {
    constructor(props){
        super(props);
        this.handleSignup = this.handleSignup.bind(this);
    }
    async handleSignup(e){
        e.preventDefault();
        const { headerEvents } = this.props;
        const firstName = document.getElementById('signup-first-name').value;
        const lastName = document.getElementById('signup-last-name').value;
        const email = document.getElementById('signup-email').value;
        const pwd = document.getElementById('signup-password').value;
        const cfm_pwd = document.getElementById('signup-cfm-password').value;
        if (pwd != cfm_pwd) {
            headerEvents("passwordMisMatch", 'Password and confirm password are not same.');
        } else {
            const server = `${API_SERVER}/auth/register`;
            const session = localStorage.getItem('key');
            let headers = new Headers();
            const formData = new FormData();
            formData.append("first_name", firstName);
            formData.append("session", session);
            formData.append("last_name",  lastName);
            formData.append("email",  email);
            formData.append("pwd",  pwd);
            console.log(server);
            document.body.style.cursor = 'wait';
            const result = await fetch(server, {
                method: "POST",
                credentials: 'include',
                body: formData,
                //headers: headers
            });
            document.body.style.cursor='default';
            const data = await result.json();
            if (data.success) {
                headerEvents("signup", data.message);
            } else {
                headerEvents("signupErr", data.message);
            }
        }
        return false;
    }
    signup() {
        const {assistant,headerEvents } = this.props;
        return (
            <div class="modal fade login-modal" id="login-modal-v2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="login-modal-v2" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                    <div class="modal-header border-0">
                        
                    </div>
                    <div class="modal-body px-4 pb-5">
                        <h5 class="modal-title text-center" id="exampleModalLabel">Sign up to build your first custom GPT</h5>
                        <div class="modal-form-box pt-4">
                            <form onSubmit = { this.handleSignup }>
                                {
                                    (assistant.password_message !='') ?
                                        <div class="alert alert-danger" role="alert">
                                        {assistant.password_message}
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    (assistant.signup_error !='') ?
                                        <div class="alert alert-danger" role="alert">
                                        {assistant.signup_error}
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    (assistant.signup_success !='') ?
                                        <div class="alert alert-success" role="alert">
                                        {assistant.signup_success}
                                        </div>
                                        :
                                        ""
                                }
                                <div class="mb-3">
                                    <input type="text" class="form-control" placeholder="First Name" id="signup-first-name" required/>
                                </div>
                                <div class="mb-3">
                                    <input type="text" required class="form-control" placeholder="Last Name" id="signup-last-name" />
                                </div>
                                <div class="mb-3">
                                    <input type="email" required class="form-control" placeholder="Email" id="signup-email" />
                                </div>
                                <div class="mb-3">
                                    <input type="password" class="form-control" placeholder="Password" id="signup-password" required minLength='8'/>
                                </div>
                                <div class="mb-3">
                                    <input type="password" class="form-control" placeholder="Confirm Password" id="signup-cfm-password" required minLength='8'/>
                                </div>
                                
                                <button type="submit" class="btn btn-primary w-100">Sign Up</button>
                            </form>
                            <div class="separator-box-main py-4">
                                <div class="separator-box"></div>
                                <div class="sep-box-text text-center"><span>Or</span></div>
                            </div>
                            <GoogleloginBtn type='signup' headerEvents = {headerEvents}/>
                            <div class="py-4 text-center bottom-link">
                                    <a data-bs-toggle="modal" href="#loginModal">Login</a>
                            </div>
                            <div class="pvt-box small text-center">
                                    <p>By continuing you agree to <a href="javascript:;">Privacy Policy</a> and <a href="javascript:;">Terms & Conditions</a></p>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return this.signup(this.props);
    }
}

export default Signup;