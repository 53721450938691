import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DesktopPreviewTab from "../components/desktop_preview_tab_content";
import DesktopEmbedTab from "../components/desktop_embed_tab_content";

class RightTabContent extends Component {
  rightTabContent(props) {
    const { message, assistant,builderTabEvents,configureTabEvents,headerEvents,previewTabEvents,embedTabEvents } = this.props;
    return (
      <div class="tab-content for-desktop-only pt-5" id="rightTabContent">
        <DesktopPreviewTab 
          message = { message}
          assistant= {assistant} 
          builderTabEvents = {builderTabEvents}
          configureTabEvents = {configureTabEvents}
          headerEvents = {headerEvents}
          previewTabEvents = {previewTabEvents}
          embedTabEvents = {embedTabEvents}
        />
        <DesktopEmbedTab 
          message = { message}
          assistant= {assistant} 
          builderTabEvents = {builderTabEvents}
          configureTabEvents = {configureTabEvents}
          headerEvents = {headerEvents}
          previewTabEvents = {previewTabEvents}
          embedTabEvents = {embedTabEvents}
        />
      </div>
    );
  }
  render(){
    return this.rightTabContent(this.props);
  }
} 
export default RightTabContent;