import { 
  streamMessageReceivedPT,
  readyState,
  addMessageCT,
  messageReceivedCT,
  socketClosed,
  runResumed,
  runComplete,
  threadAdd,
  browsingWeb,
  runCreated,
  setupComplete,
  leadComplete,
  messageReceivedPT,
  addMessagePT,
  configUpdate,
  streamMessageReceivedCT,
  buildStarted,
  enablePreviewTab,
  saveConfigComplete
} from '../actions';
import {WEB_SOCKET_SERVER} from '../constants/env';
const generateKey = (se) => {
  return `${ se }_${ new Date().getTime() }`;
}
let timer = 250;
const widget_incoming = document.getElementById("widget_incoming2"); 
const SERVER_ADDRESS = document.location.host;

const addStreammessage = (data,dispatch, type = 'PT') => {
  let author = data.author;
  let feedBack = data.feedBack;
  let cDate = new Date();
  let finalDate = cDate;
  let lmore = '';
  if (data.lmore) lmore  = data.lmore;
  let mesage = data.message;
  if (data.sub_type != 'STREAM_FINAL') mesage = `${data.message}<span style="font-size:20px; color:#333;"> &#9679;</span>`;
  if (timer <= 1500){
    if (type == 'PT')
      dispatch(streamMessageReceivedPT(mesage,author,finalDate,feedBack,lmore));
    else if (type == 'CT')
      dispatch(streamMessageReceivedCT(mesage,author,finalDate,feedBack,lmore));
    timer +=80;
  } else {
    setTimeout( ()=>{
      if (type == 'PT')
        dispatch(streamMessageReceivedPT(mesage,author,finalDate,feedBack,lmore));
      else if (type == 'CT')
        dispatch(streamMessageReceivedCT(mesage,author,finalDate,feedBack,lmore));
    }, timer);
    timer +=80;
  }

  if (data.sub_type == 'STREAM_FINAL') timer = 250;
};

const setupSocket = (dispatch, store) => {
  const storedKey = localStorage.getItem("key");
  console.log('calling ga4 event');

  let key = "";
  if(storedKey){
    key = storedKey;
  }else{
    key = generateKey("sess")
    localStorage.setItem('key', key); 
  }
  const socket = new WebSocket(WEB_SOCKET_SERVER+'session_id='+key);
  //const socket = new WebSocket('wss://'+SERVER_ADDRESS+':8988?session_id='+key);

  socket.onopen = () => {
    //const {bot_id} = queryString.parse(window.location.search);
  }
  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    const state = store.getState();
    let messages = 0;

    console.log('recieved data from socket is', data);
    switch (data.type) {
      case 'CONNECTED':
        console.log('socket_connectd');
        const { ct_instruction, configTab_instruction,pt_instruction,en_instruction} = data;
        const instructionSet = {
          ct_instruction,
          configTab_instruction,
          pt_instruction,
          en_instruction,
        }
        dispatch(readyState(instructionSet));
        break;
      case 'ADD_MESSAGE_CT':
        dispatch(messageReceivedCT(data.message, data.author,0,0,data.feedBack));
        break
      case 'ADD_MESSAGE_PT':
          dispatch(messageReceivedPT(data.message, data.author,0,0,data.feedBack));
          break
      case 'ADD_MESSAGE_STREAM_PT':
        timer = 250;
        addStreammessage(data,dispatch);
        break;
      case 'ADD_MESSAGE_STREAM_CT':
        timer = 250;
        addStreammessage(data,dispatch,'CT');
        break;
      case 'MESSAGE_RESUME_RUN':
        dispatch(runResumed(data.message, data.p_message)); //status 75%
        break;
      case 'MESSAGE_RUN_COMPLETE':
        dispatch(runComplete(data.message, data.p_message)); // status 90%
        break;
      case 'MESSAGE_ADDED_THREAD':
        dispatch(threadAdd(data.message, data.p_message)); //not show status
        break;
      case 'MESSAGE_BROWSING_WEB':
        dispatch(browsingWeb(data.message, data.p_message)); //show loading bar 50%
        break;
      case 'MESSAGE_RUN_CREATED':
        dispatch(runCreated(data.message, data.p_message)); //not show
        break;
      case 'SETUP_COMPLETE':
        const setUpData = JSON.parse(data.message);
        const cleanedLocations = [];
        if (setUpData.locations && !Array.isArray(setUpData.locations) && setUpData.locations.length > 0){
          try {
            const locations = JSON.parse(setUpData.locations);
            console.log(locations);
            setUpData.locations = locations;
            console.log(setUpData);
          } catch(e) {
            console.error(e);
            const locdata = {
              name: setUpData.locations,
              address: '',
              city: '',
              state: '',
              zip: '',
              users: []
            };
            setUpData.locations = [];
            setUpData.locations.push(locdata);
          }
        }
        let locCount = 1;
        setUpData.locations.forEach(item => {
          console.log(item);
          const itemName = `loc${locCount}`;
          const itemName2 = `LOC${locCount}`;
          let locObj = item;
          console.log(item[itemName]);
          if (item?.[itemName]) locObj = item[itemName];
          if (item?.[itemName2]) locObj = item[itemName2];
          console.log(locObj);
          const user = {};
          if (locObj.contact_email) user.email = locObj.contact_email;
          if(locObj.contact_name) user.name = locObj.contact_name;
          if(!locObj.name && locObj.location_name) locObj.name = locObj.location_name;
          if(!locObj.name && locObj.location) locObj.name = locObj.location;
          locObj.users = [];
          locObj.users.push(user);
          console.log(locObj);
          cleanedLocations.push(locObj)
          locCount++;
        });
        const team = {
          name: '',
          users: [],
        }
        setUpData.teams = [];
        setUpData.teams.push(team);
        setUpData.locations = cleanedLocations;
        console.log(setUpData);
        dispatch(setupComplete(setUpData));
        const params = {
                message: "show_default_welcome",
                author: 'Me',
                feedback: true,
            }
        const session = localStorage.getItem('key');
        params.session = session;
        dispatch(addMessagePT(params));
        dispatch(enablePreviewTab(true));
        break;
      case 'LEAD_COMPLETE':
        const leadData = JSON.parse(data.message);
        console.log(leadData);
        dispatch(leadComplete(leadData)); 

      case 'UPDATE_INSTRUCTIONS_COMPLETE':
        dispatch(configUpdate(data.message));
      case 'SAVE_CONFIG_COMPLETE':
        dispatch(saveConfigComplete(data.message));
        document.body.style.cursor = 'default';
        dispatch(enablePreviewTab(true));
        const sessionx = localStorage.getItem('key');
        dispatch(
          addMessagePT({
            message: "show_default_welcome",
            author: 'Me',
            feedback: true,
            session: sessionx
          })
        );
        break;
      case 'BUILD_STARTED':
        dispatch(buildStarted())
      default:
        break
    }
  }

  socket.onclose = function(){
    console.log("socket on close event")
    dispatch(socketClosed());
  };
  return socket
}

export default setupSocket;