import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class DeveloperOptionsComponent extends Component{
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.handleBack = this.handleBack.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }
    handleBack(e){
        const { configureTabEvents } = this.props;
        configureTabEvents('showDo',false);
    }
    handleSave(e) {
        const create_instrucions = document.getElementById('ct_instruction').value;
        const config_instructions = document.getElementById('configTab_instruction').value;
        const preview_instructions = document.getElementById('pt_instruction').value;
        const en_instruction = document.getElementById('en_instruction').value;
        const { configureTabEvents } = this.props;
        const param = {
            create_instrucions,
            config_instructions,
            preview_instructions,
            en_instruction
        };
        configureTabEvents('updateInstrcutions',param);
    }
    developerOptionsComponent(props) {
        const { assistant } = props;
         return(
            <div class="tab-pane fade active show" id="leftTab2" role="tabpanel" aria-labelledby="leftTab2-tab">
                <div class="form-box location-form-box">
                    <div class="form-wrap">
                        <form>
                            <fieldset class="field-set-0 text-fieldset">
                                <div class="input-group mb-5">
                                    <div className="back-button-box">
                                        <a onClick={this.handleBack} href="javascript:;"><i className="fa fa-chevron-left"></i></a>
                                    </div>
                                    <h4 class="text-center mb-4">
                                        Update Instructions (Advanced users)
                                    </h4>
                                    <small class="text-center">Update your inbound GPT Create Tab, Configure Tab, Preview Tab and Email Notifications Instructions.</small>
                                </div>
                            </fieldset>
                            <fieldset class="field-set-1">
                                <label for="company-name" class="form-label">Create Tab instructions</label>
                                <div class="input-group mb-3">
                                    <textarea ref={this.myRef} defaultValue={assistant.ct_instruction} class="form-control" id="ct_instruction" rows="15"/>
                                </div>
                            </fieldset>
                            <fieldset class="field-set-1">
                                <label for="company-name" class="form-label">Configure Tab instructions</label>
                                <div class="input-group mb-3">
                                    <textarea ref={this.myRef} defaultValue={assistant.configTab_instruction} class="form-control" id="configTab_instruction" rows="15"/>
                                </div>
                            </fieldset>
                            <fieldset class="field-set-1">
                                <label for="company-name" class="form-label">Preview Tab instructions</label>
                                <div class="input-group mb-3">
                                    <textarea ref={this.myRef} defaultValue={assistant.pt_instruction} class="form-control" id="pt_instruction" rows="15"/>
                                </div>
                            </fieldset>
                            <fieldset class="field-set-1">
                                <label for="company-name" class="form-label">Email Notifications instructions</label>
                                <div class="input-group mb-3">
                                    <textarea ref={this.myRef} defaultValue={assistant.en_instruction} class="form-control" id="en_instruction" rows="15"/>
                                </div>
                            </fieldset>
                            <button onClick={this.handleSave} type="button" class="btn btn-primary">Save changes</button>
                            {
                                (assistant.showCompleteBox) ? 
                                <div class="alert alert-success" role="alert">
                                    {assistant.instructionMessage}
                                </div>
                                :
                                ""
                            }  
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    render() {
        return this.developerOptionsComponent(this.props);
    }
}

export default DeveloperOptionsComponent;
