import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GoogleloginBtn from './google_login';
import assistant from "../reducers/assistant";
import { API_SERVER } from '../constants/env';
import Inbound from "./modals/inbound";
import Login from "./modals/login";
import Signup from "./modals/signup";
import ResetEmail from "./modals/resetEmail";
import ResetPwd from "./modals/resetPwd";
class HeaderItems extends Component {
    constructor(props){
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleDMClick = this.handleDMClick.bind(this);
        this.logout = this.logout.bind(this);
    }
    handleClick(e) {
        //this.setState({ value: e.target.value });
        const { headerEvents } = this.props;
        console.log('the input message typed is ', e.target.getAttribute('data-item') );
        const m = e.target.getAttribute('data-item');
        console.log(m.length);
        headerEvents("selectGptAgent", m);
    }
    handleDMClick() {
        const { headerEvents } = this.props;
        headerEvents("addFullwidth", '');
    }
    async logout(e) {
        //destroy cookie
        e.preventDefault();
        const { headerEvents } = this.props;
        //await fetch('https://app.siteglue.ai/logout', {
        await fetch(API_SERVER+'/auth/logout', {
            method: 'POST',
            credentials: 'include',
        });
        //set state
        headerEvents("logout", '');
    }
    headerItems() {
        const {assistant,headerEvents, builderTabEvents } = this.props;
        return (
          <div class="wrapper">
                              <div class="row">
                                  <div class="col-6 left-box">
                                          <div class="left-box-wrap">
                                              <div class="row">
                                                  <div class="left-image-box">
                                                      <a href="javascript:;"><img src="/images/Color logo - no background.png" class="logo" alt="paper image" width="200" height="auto"/></a>
                                                  </div>
                                                  <div class="right-content-box">
                                                  
                                                      <div class="chat-text-box">
                                                        <nav class="navbar navbar-dark bg-dark">
                                                            <div class="container-fluid-nav">
                                                                <button onClick={this.handleDMClick} class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#myCollapsible" aria-controls="myCollapsible" aria-expanded="true" aria-label="Toggle navigation">
                                                                    <img src='/images/IconOpen.png' />
                                                                </button>
                                                            </div>
                                                        </nav>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                  </div>
                                  <div class="col-6 right-box d-flex flex-column justify-content-end align-items-end">
                                  <button class="btn btn-primary right-menu-button" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                                                          <i class="fa fa-bars"></i>
                                                        </button>
                                                        
                                                        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                                                          <div class="offcanvas-header">
                                                            <h5 class="offcanvas-title" id="offcanvasExampleLabel"></h5>
                                                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                                          </div>
                                                          <div class="offcanvas-body">
                                                          <div class="links-box pt-5">
                                                              <ul class="top">
                                                              <li class="active"><a target="__blank" href="https://easygpt.builders/gptbuilders/">Easy GPT Builders</a></li>
                                                              <li><a target="__blank" href="https://easygpt.builders/features/">Features</a></li>
                                                              <li><a target="__blank" href="https://easygpt.builders/usecases/">Case Studies</a></li>
                                                              <li><a target="__blank" href="https://easygpt.builders/about/">About Us</a></li>
                                                              </ul>
                                                              <ul class="bottom">
                                                              <li class="active"><a href="javascript:;">Contact Us</a></li>
                                                              <li><a target="__blank" href="https://easygpt.builders/affiliates">Affiliates</a></li>
                                                              <li><a target="__blank" href="https://support.easygpt.builders">Support</a></li>
                                                              <li><a target="__blank" href="https://easygpt.builders/privacy/">Privacy Policy</a></li>
                                                              <li><a target="__blank" href="https://easygpt.builders/terms/">Terms & Conditions</a></li>
                                                              </ul>
                                                          </div>
                                                          </div>
                                                        </div>
                                      <div class="dropdown">
                                            {
                                                (assistant.user?.availableCredits)
                                                ?
                                                    <span style={{ paddingRight: "10px"}}> Total available credits : { assistant.user?.availableCredits }</span>
                                                :
                                                ""
                                            }
                                          <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                            &nbsp;Welcome { (assistant.user?.firstName) ? assistant.user.firstName : ""  }
                                          </a>
                                      
                                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            {
                                                !(assistant.loggedIn) ?
                                                <li><a id='menuItem1' data-controls-modal="loginModal" data-backdrop="static" data-keyboard="false"  class="dropdown-item" data-bs-toggle="modal"  href="#loginModal">Login</a></li>
                                                :
                                                <li><a id='menuItem1b' class="dropdown-item" onClick = {this.logout}  href="javascript:;">Logout</a></li>
                                            }
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                              <Inbound assistant={assistant} headerEvents={headerEvents} builderTabEvents={ builderTabEvents } />
                               <Login assistant= {assistant} headerEvents = {headerEvents} />
                                <Signup assistant= {assistant} headerEvents = {headerEvents} />
                                <ResetEmail assistant= {assistant} headerEvents = {headerEvents} />
                                <ResetPwd assistant= {assistant} headerEvents = {headerEvents} />
                          </div>
        );
    }

    render() {
        return this.headerItems(this.props);
    }
}

export default HeaderItems;