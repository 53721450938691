import React, { Component } from 'react';
//class="custom-button"
const createMarkup = (html) => {
    html = html.replaceAll(/class"quick_reply"/g, 'class="custom-button"');
    html = html.replaceAll(/class="quick_reply"/g, 'class="custom-button"')
    return { __html: html };
};
const parseMessage = (message, configureData)=>{
    let cmessage = message.toLowerCase();
    let n = cmessage.indexOf('<button');
    let l = cmessage.lastIndexOf('</button>');
    l = l  + 8;
    let extract = message.substring(n, l);
    const urlRegex = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    if (null != extract.match(urlRegex)) {
      let end = message.lastIndexOf('</a>');
      let text = message.substring(end+4,message.length );
      console.log(end)
      console.log(text)
      let start = text.indexOf('<');
      extract = text.substring(start, l);
      n = message.indexOf(extract);
    }
    var finalMessage = "";
    let appendHTML = "";
    //if(/<[a-z][\s\S]*>/i.test(extract)){
    if (/<button[\s\S]*>/i.test(extract)) {
        appendHTML = `  `+extract+` 
                    `;
        var str1 = message.substring(0,n);
        var str2 = message.substring(l+1);
        finalMessage = str1+str2;
    }else{
      finalMessage = message;
    }
    if (appendHTML != "") {
        console.log("buttons html ", appendHTML)
        appendHTML = replaceFaqs(appendHTML, configureData);
        console.log("buttons html ", appendHTML)
        appendHTML = replaceCtas(appendHTML, configureData);
        console.log("buttons html ", appendHTML)
    }
    return {"text":finalMessage, "buttons":appendHTML}
}
const replaceFaqs = (html, data) => {
    let faq1 = '', faq2 = '', faq3 = '', faq4 = '', faq5 = '';
    let faqs = [];
    if (data?.faqs) {
        if (typeof data.faqs != 'object')
            faqs = data.faqs.split("?");
        faq1 = (undefined !== faqs && undefined != faqs[0]) ? faqs[0]: '';
        faq2 = (undefined !== faqs && undefined != faqs[1]) ? faqs[1]: '';
        faq3 = (undefined !== faqs && undefined != faqs[2]) ? faqs[2]: '';
        faq4 = (undefined !== faqs && undefined != faqs[3]) ? faqs[3] : '';
        faq5 = (undefined !== faqs && undefined != faqs[4]) ? faqs[4]: '';
    }

    if (data?.conversation_starters) {
        faqs = data.conversation_starters;
        faq1 = (undefined !== faqs && undefined != faqs[0]) ? faqs[0]: '';
        faq2 = (undefined !== faqs && undefined != faqs[1]) ? faqs[1]: '';
        faq3 = (undefined !== faqs && undefined != faqs[2]) ? faqs[2]: '';
        faq4 = (undefined !== faqs && undefined != faqs[3]) ? faqs[3] : '';
        faq5 = (undefined !== faqs && undefined != faqs[4]) ? faqs[4] : '';
        faq1 = (undefined !== faqs && undefined != faqs[0] && undefined != faqs[0]['FAQ1']) ? faqs[0]['FAQ1']: faqs[0];
        faq2 = (undefined !== faqs && undefined != faqs[1] && undefined != faqs[1]['FAQ2']) ? faqs[1]['FAQ2']: faqs[1];
        faq3 = (undefined !== faqs && undefined != faqs[2] && undefined != faqs[2]['FAQ3']) ? faqs[2]['FAQ3']: faqs[2];
        faq4 = (undefined !== faqs && undefined != faqs[3] && undefined != faqs[3]['FAQ4']) ? faqs[3]['FAQ4'] : faqs[3];
        faq5 = (undefined !== faqs && undefined != faqs[4] && undefined != faqs[4]['FAQ5']) ? faqs[4]['FAQ5'] : faqs[4];
    }
    const replaceBy = [faq1, faq2, faq3, faq4, faq5];
    const search = ['faq1', 'faq2', 'faq3', 'faq4', 'faq5'];
    replaceBy.forEach((item, index) => {
        if (item != '') {
            console.log('to replace is ', search[index]);
            console.log('replace with ', item)
            html = html.toLowerCase().replace(search[index], item);
        }
    })
    return html;
}
const replaceCtas = (html, data) => {
    let cta1 = '', cta2 = '', cta3 = '';
    let ctas = [];
    if( data?.cta_labels ) {
        ctas = data.cta_labels.split(",");
        cta1 = (undefined !== ctas && undefined != ctas[0]) ? ctas[0]: '';
        cta2 = (undefined !== ctas && undefined != ctas[1]) ? ctas[1]: '';
        cta3 = (undefined !== ctas && undefined != ctas[2]) ? ctas[2]: '';
    }
    if( data?.call_to_action_buttons) {
        ctas = data.call_to_action_buttons;
        cta1 = (undefined !== ctas && undefined != ctas[0]) ? ctas[0]: '';
        cta2 = (undefined !== ctas && undefined != ctas[1]) ? ctas[1]: '';
        cta3 = (undefined !== ctas && undefined != ctas[2]) ? ctas[2] : '';
        cta1 = (undefined !== ctas && undefined != ctas[0] && undefined != ctas[0]['CTA1']) ? ctas[0]['CTA1']: ctas[0];
        cta2 = (undefined !== ctas && undefined != ctas[1] && undefined != ctas[1]['CTA2']) ? ctas[1]['CTA2']: ctas[1];
        cta3 = (undefined !== ctas && undefined != ctas[2] && undefined != ctas[2]['CTA3']) ? ctas[2]['CTA3']: ctas[2];
    }
    const replaceBy = [cta1, cta2, cta3];
    const search =  ['cta1', 'cta2', 'cta3'];
    replaceBy.forEach((item, index) => {
        if (item != '') { 
            html = html.toLowerCase().replace(search[index], item);
        }
    })
    return html;
}
class MessagePreview extends Component {
    messagePreview (props) {
        const { message, assistant, messageRec, handleQrbClick } = props;
        let ppicSrc = '/images/co-pilot.png';
        let utitle = assistant.selectedGpt;
        const messageObj = parseMessage(messageRec.message, assistant.configureTabData);
        if (messageRec.author === "Me") {
            ppicSrc = 'https://s.gravatar.com/avatar/ef0cdc44e23d4660c432255ff17ae9c4?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fjw.png';
            utitle = 'You';
            return (
                <div class="user-message py-3">
                  <div class="widgetgpt-conversation-item d-flex align-items-end justify-content-end gap-2 px-3">
                    <span onClick={handleQrbClick} dangerouslySetInnerHTML={createMarkup(messageObj.text)} class="widgetgpt-conversation-message small inline-flex w-full flex-col gap-2 overflow-x-auto px-3 py-3"></span>
                  </div>
                  <span class="user-name-details flex-1 small d-inline-flex w-100 flex-column gap-2 overflow-x-auto px-3 align-items-end">{utitle}</span>
                </div>
            );
        } else {
            ppicSrc = 'https://s.gravatar.com/avatar/ef0cdc44e23d4660c432255ff17ae9c4?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fjw.png';
            utitle = assistant.configureTabData.inbound_gpt_agent_name;
            return (
                <div class="bot-message py-3">
                  <div class="widgetgpt-conversation-item d-flex align-items-end gap-2 px-3">
                    <img class="avatar-icon" src="/images/co-pilot.png" alt="Avatar" width="32" height="auto" />
                        <span onClick={handleQrbClick}
                            dangerouslySetInnerHTML={createMarkup(messageObj.text)}
                            class="widgetgpt-conversation-message flex-1 small d-inline-flex w-100 flex-column gap-2 overflow-x-auto px-3 py-3">
                        </span>
                  </div>
                    <span class="bot-name-details small overflow-x-auto px-3 py-2">{utitle}</span>
                    {
                        (messageObj.buttons != "") ? 
                         <div class="btn-group-box d-flex flex-column align-items-center px-3 py-3">
                                <div class="d-flex flex-wrap justify-content-start gap-2 btn-group-box-wrap" onClick={handleQrbClick} dangerouslySetInnerHTML={createMarkup(messageObj.buttons)}>
                                    
                                </div>
                            </div>
                        : ""
                    }
                </div>
            );
        }
    }
    render() {
        return this.messagePreview(this.props);
    }
}

export default MessagePreview
