import React, { Component } from "react";
import { API_SERVER } from '../../constants/env';
class ResetEmail extends Component {
    constructor(props){
        super(props);
        this.handleResetLogin = this.handleResetLogin.bind(this);
    }
    async handleResetLogin(e) {
        e.preventDefault();
        const { headerEvents } = this.props;
        const email = document.getElementById('reset-modal-email').value;
        const server = `${API_SERVER}/auth/reset`;
        const session = localStorage.getItem('key');
        let headers = new Headers();
        const formData = new FormData();
        formData.append("email",  email);
        console.log(server);
        document.body.style.cursor = 'wait';
        const result = await fetch(server, {
            method: "POST",
            credentials: 'include',
            body: formData,
            //headers: headers
        });
        document.body.style.cursor='default';
        const data = await result.json();
        if (data.success) {
            headerEvents("reset", data.message);
        } else {
            headerEvents("resetErr", data.message);
        }
        return false;
    }
    resetEmail() {
        const {assistant,headerEvents } = this.props;
        return (
             <div class="modal fade login-modal" id="resetPwdModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="resetPwdModal" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                    <div class="modal-header border-0">
                        
                    </div>
                    <div class="modal-body px-4 pb-5">
                        <h5 class="modal-title text-center" id="exampleModalLabel">Reset your password</h5>
                        <div class="modal-form-box pt-4">
                            <form onSubmit = { this.handleResetLogin } >
                                    {
                                    (assistant.reset_message !='') ?
                                        <div class="alert alert-danger" role="alert">
                                        {assistant.reset_message}
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    (assistant.reset_message_success !='') ?
                                        <div class="alert alert-success" role="alert">
                                        {assistant.reset_message_success}
                                        </div>
                                        :
                                        ""
                                }
                                <div class="mb-3">
                                    <input type="email" class="form-control" placeholder="Email" id="reset-modal-email" required/>
                                </div>
                                <button  type="submit" class="btn btn-primary w-100">Reset Password</button>
                                <div class="mb-3 d-flex w-100 reset-pwd-box">
                                    <a data-bs-toggle="modal" href="#loginModal">Back to login</a>
                                </div>
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return this.resetEmail(this.props);
    }
}

export default ResetEmail;