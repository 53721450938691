//import { type } from "@testing-library/user-event/dist/type";

let nextMessageId = 1
let nextUserId = 0
export const clickedMessage =  () => ({
  type: 'SOCKET_CLOSED',
});

export const socketClosed = () => ({
  type: 'SOCKET_CLOSED',
});

export const readyState = (data) => ({
  type: 'SOCKETCONCONNECTED',
  instructionSet: data,
});

//export const addMessageCT = (message, author,session,feedback) => ({
export const addMessageCT = (params) => ({
  type: 'ADD_MESSAGE_CT',
  id: nextMessageId++,
  message: params.message,
  author: params.author,
  session: params.session,
  feedback: params.feedBack,
})
export const typingStartCT = (status) => ({
  type: 'TYPING_CREATE_TAB',
  status,
})
export const typingStartPT = (status) => ({
  type: 'TYPING_P_TAB',
  status,
});
export const showLocation = (data) => ({
  type: 'SHOW_LOCATION',
  show: data.toggle,
  index: data.index
});
export const showTeam = (data) => ({
  type: 'SHOW_TEAM',
  show: data.toggle,
  index: data.index
});
export const showUser = (data) => ({
  type: 'SHOW_USER',
  show: data.toggle,
  index: data.index
});
export const showInsruction = (toogle) => ({
  type: 'SHOW_INSTRUCTION',
  show: toogle,
});
export const showDo = (toogle) => ({
  type: 'SHOW_DEVELOPER_OPTIONS',
  show: toogle,
});
export const showKb = (toogle) => ({
  type: 'SHOW_KNOWLEDGE_BASE',
  show: toogle,
});
export const messageWillbeReceived = (feedBack) => ({
  feedBack,
  type:'MESSAGE_WILL_BE_RECEIVED',
  id: nextMessageId++
});

export const selectAssistant = (selectedGpt) => ({
  type: 'SELECT_ASSITANT',
  selectedGpt
});

export const uploadSucess = (msg) => ({
  type: 'UPLOAD_SUCESS',
  msg
});


export const uploadError = (msg) => ({
  type: 'UPLOAD_ERROR',
  msg
});

export const clearuploadMsg = (msg) => ({
  type: 'CLEAR_UPLOAD_MSG',
  msg
});


export const qrbClicked = (webiste_url) => ({
  type: 'QRB_CLICK',
  webiste_url
});

export const runResumed = (statusMessage, statusPMessage) => ({
  type: 'RUNRESUMED',
  statusMessage,
  statusPMessage
});

export const runComplete = (statusMessage, statusPMessage) => ({
  type: 'RUNCOMPLETE',
  statusMessage,
  statusPMessage
});

export const threadAdd = (statusMessage, statusPMessage) => ({
  type: 'THREADMESSAGECREATE',
  statusMessage,
  statusPMessage
});

export const browsingWeb = (statusMessage, statusPMessage) => ({
  type: 'BROWSINGWEB',
  statusMessage,
  statusPMessage
});

export const runCreated = (statusMessage) => ({
  type: 'ENABLEMULSEL',
  statusMessage,
});

export const setupComplete = (configureData) => ({
  type: 'SETUPCOMPLETE',
  configureData,
});

export const leadComplete = (leadData) => ({
  type: 'LEADCOMPLETE',
  leadData
});
export const addMessagePT = (params) => ({
  type: 'ADD_MESSAGE_PT',
  id: nextMessageId++,
  message: params.message,
  author: params.author,
  session: params.session,
  feedback: params.feedBack,
  defaultUser: params.user
})
export const updateInstrcutions = (params) => ({
  type: 'UPDATE_INSTRUCTIONS',
  data: params,
});
export const configUpdate = (msg) => ({
  type: 'UPDATE_INSTRUCTIONS_COMPLETE',
  msg 
});
export const saveConfig = (params) => ({
  type: 'SAVE_CONFIG',
  data: params,
});
export const saveConfigComplete =(msg) => ({
  type: 'SAVE_CONFIG_COMPLETE',
  msg
});
export const messageReceivedPT = (message,author,extraChatsCount,dtime,feedBack) => ({
  type: 'MESSAGE_RECEIVED_PT',
  id: nextMessageId++,
  message,
  extraChatsCount:extraChatsCount,
  dtime:dtime,
  feedBack,
  author,
})
export const messageReceivedCT = (message,author,extraChatsCount,dtime,feedBack) => ({
  type: 'MESSAGE_RECEIVED_CT',
  id: nextMessageId++,
  message,
  extraChatsCount:extraChatsCount,
  dtime:dtime,
  feedBack,
  author,
})

export const additionalMessageRecieved = (message,extraChatsCount,dtime,feedBack, lmore = '', showAncButton) =>({
  type:"ADD_EXTRA_MESSAGE",
  extra_message:message,
  dtime:dtime,
  extraChatsCount:extraChatsCount,
  feedBack,
  lmore,
  showAncButton
})

export function messageError(errors) {  
  return {
    type: "CHAT_VALIDATION_ERROR",
    errors
  }
}
export const  showSearchWebsiteMessage = () => ({
  type: 'SHOWSEARCHWEBSITE',
});

export const  hideSearchWebsiteMessage = () => ({
  type: 'HIDESEARCHWEBSITE',
});

export const disableSearchWebsiteMessage = () => ({
  type: 'DISABLESEARCHWEBSITE',
});

export const enableSearchWebsiteMessage = () => ({
  type: 'ENABLESEARCHWEBSITE',
});

export const enableAppointmentForm = ( action, message) => ({
  type: 'SHOWAPPOINTMENTFORM',
  intentAction: action,
  intentMessage: message
});

export const addFullwidth = () => ({
  type: 'ADD_FULL_WIDTH',
});

export const removeFullwidth = () => ({
  type: 'REMOVE_FULL_WIDTH',
});

export const changeQuickReplyMessage = ( action, message) => ({
  type: 'CHANGEQUICKREPLYMESSAGE',
  intentAction: action,
  intentMessage: message
});

export const continueAction = () => ({
  type: 'CONTINUEACTION',
});

export const continueActionNoLoading = () => ({
  type: 'CONTINUEACTIONNOLOADING',
});

export const addFormData = (formData) => ({
  type: 'PARAMETERS_POST_REQUEST',
  formData
});

export const showLoading = () => ({
  type: 'SHOWFEEDBACKTEXT'
});

export const waitForForm = () => ({
  type: 'WAITINGFORFORM'
});

export const showFormError = () => ({
  type: 'SHOWFORMERROR'
});

export const restart_chat = () =>({
  type: 'RESTART_CHAT'
});

export const clearMessage = () => ({
  type: 'REMOVE_MESSAGES'
});

export const streamMessageReceivedPT = (message,author,dtime,feedBack,lmore) => ({
  type: 'ADD_MESSAGE_STREAM_PT',
  id: nextMessageId++,
  message,
  dtime:dtime,
  feedBack,
  author,
  lmore
});
export const streamMessageReceivedCT = (message,author,dtime,feedBack,lmore) => ({
  type: 'ADD_MESSAGE_STREAM_CT',
  id: nextMessageId++,
  message,
  dtime:dtime,
  feedBack,
  author,
  lmore
})
export const  buildStarted = () => ({
  type: 'BUILD_STARTED',
});

export const login = (user = {}) => ({
  type: 'LOGIN',
  user,
});

export const logout = (user = {}) => ({
  type: 'LOGOUT',
  user,
});;

export const loginFailed = (message) => ({
  type: 'LOGIN_FAILED',
  message
});

export const passwordMisMatch = (message) => ({
  type: 'PASSWORD_DIFFERENT',
  message
});
export const signupErr = (message) => ({
  type: 'SIGNUP_FAILED',
  message
});
export const signup = (message) => ({
  type: 'SIGNUP',
  message
});

export const loginShown = (loginModalShown) => ({
  type: 'LG_MODAL_SHOWN',
  loginModalShown
});

export const showSplashScreen = (loading) => ({
  type: 'SPLASH_SCREEN',
  loading
});

export const resetErr = (msg) => ({
  type: 'RESET_ERR',
  msg
});

export const reset = (msg) => ({
  type: 'RESET',
  msg
});

export const showCreateTab = (show) => ({
  type: 'SHOW_CREATE_TAB',
  show,
});

export const enablePreviewTab = (show) => ({
  type: 'SHOW_PREVIEW_TAB',
  show,
});