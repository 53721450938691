import { 
    showLocation,
    showTeam,
    showUser,
    showInsruction,
    showKb,
    showDo,
    updateInstrcutions,
    saveConfig
} from '../actions/index';
class ConfigureEvents  {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }
    static getInstance(dispatch) {
        if (!ConfigureEvents.instance) {
            ConfigureEvents.instance = new ConfigureEvents(dispatch);
        }
        return ConfigureEvents.instance; 
    }
    showLocation(param){
        console.log(param);
        this.dispatch(showLocation(param));
    }
    showTeam(param){
        console.log(param);
        this.dispatch(showTeam(param));
    }
    showUser(param){
        console.log(param);
        this.dispatch(showUser(param));
    }
    showInstruction(param){
        this.dispatch(showInsruction(param));
    }
    showKb(param){
        this.dispatch(showKb(param));
    }
    showDo(param){
        this.dispatch(showDo(param));
    }
    updateInstrcutions(param){
        this.dispatch(updateInstrcutions(param));
    }
    saveConfig(param) { 
        this.dispatch(saveConfig(param));
    }
}
export default ConfigureEvents;