import React, { Component } from "react";
import { API_SERVER } from '../../constants/env';
class Inbound extends Component {
    constructor(props){
        super(props);
        this.launchCreateProcess = this.launchCreateProcess.bind(this);
    }
    async launchCreateProcess(e) {
        e.preventDefault();
        const { builderTabEvents } = this.props;
        const url = document.getElementById('inbound_site_url').value;
        if (url != '' && url.length > 0){
            builderTabEvents("dispatchMessageSent", {
                message: url,
                author: 'Me',
                feedback: true,
            });
            const inboundModal = document.getElementById('inboundModal');
            const modal = window.bootstrap.Modal.getInstance(inboundModal)
            modal.hide();
            builderTabEvents("showCreateTab", true);
        }
        return false;
    }
    inbound() {
        const {assistant,headerEvents } = this.props;
        return (
             <div class="modal fade" id="inboundModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                    <div class="modal-header border-0">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body px-4 pb-5">
                    <h5 class="modal-title mb-4 text-center" id="exampleModalLabel">Create your Inbound GPT</h5>
                        <div class="modal-description-box overflow-y-auto">
                            <p>
                                Enter your website URL to build and deploy an Inbound GPT for your website to accurately answer questions, support existing customers, identify new oppurtunities, ask the right questions, and send your qualified leads based on your ideal customer profile.
                            </p>
                        </div>
                        <div class="modal-form-box pt-4">
                        <form onSubmit = { this.launchCreateProcess }>
                            <div class="mb-3">
                                <input type="url" class="form-control" placeholder="https://yourwebsite.com" id="inbound_site_url" required/>
                            </div>
                            <button type="submit" class="btn btn-primary w-100">Create my Inbound GPT</button>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return this.inbound(this.props);
    }
}

export default Inbound;