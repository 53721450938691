import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MessagePreview from "./messagePreview";
import Loadingstatus from "./loading_status";
class DesktopPreviewTab extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleQrbClick = this.handleQrbClick.bind(this);
        this.sendButtonClick = this.sendButtonClick.bind(this);
        this.input = '';
        this.messagesEnd = null;
    }
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
    
    componentDidMount() {
        this.scrollToBottom();
    }
    
    componentDidUpdate() {
        this.scrollToBottom();
    }
    handleChange(e) {
        //this.setState({ value: e.target.value });
        const { previewTabEvents } = this.props;
        console.log('the input message typed is ', e.target.value );
        const m = e.target.value;
        console.log(m.length);
        if (m.length > 0) previewTabEvents("dispatchMessageInput", true);
        else previewTabEvents("dispatchMessageInput", false);
    }
    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.addMessage();
        }
    }
    sendButtonClick(e){
        this.addMessage();
    }
    handleQrbClick(e){
        const text = e.currentTarget.getAttribute('data-text');
        const { previewTabEvents, assistant } = this.props;
        if (text != null && text!=''){
            previewTabEvents("dispatchMessageSent", {
                message: text,
                author: 'Me',
                feedback: true,
                user: assistant.user
            });
            previewTabEvents("qrbClicked", '');
       } else if ((e.target.nodeName === "BUTTON" || e.target.nodeName === "A")) {
            const { builderTabEvents, message } = this.props;
            previewTabEvents("dispatchMessageSent", {
                message: e.target.innerHTML,
                author: 'Me',
                feedback: true,
                user: assistant.user
            });
        }
    }
    addMessage(){
        if(this.input.value.length > 0){
            const { previewTabEvents, assistant } = this.props;
            previewTabEvents("dispatchMessageSent", {
                message: this.input.value,
                author: 'Me',
                feedback: true,
                user: assistant.user
            });
            this.input.value = '';
        }
    }
    desktopPreviewTab(props) {
        const { assistant, message } = props;
        const disableInput = !assistant.disablePreviewTab;
        let sendButtonStatus = "text-white p-0.5 border border-black rounded-lg transition-colors";
        if (message.typingPTab) sendButtonStatus = "text-white p-0.5 border border-black rounded-lg transition-colors active";
        return (
          <div class="tab-pane preview-tab fade show active h-100" id="rightTab1" role="tabpanel" aria-labelledby="rightTab1-tab">
              <div class="widgetgpt-container d-flex flex-column">
                  <div class="chat-list-box">
                        { (!assistant.disablePreviewTab) ?
                            <div class="chat-list-box-wrap">
                                <div class="right-chat-body-box">
                                    <div class="chat-text-box">
                                        <div class="title"><strong>Welcome to Inbound GPT Builder</strong></div>
                                    </div>
                                </div>
                                {
                                   (message.buildStarted) ?
                                   <>
                                    <div class="right-chat-body-box mt-5">
                                        <div class="chat-text-box">
                                            <div class="title"><strong>We're building your Inbound GPT now.</strong></div>
                                            <div class="chat-description">
                                                <p>This usually takes between 1-2 minutes</p>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                   :
                                   "" 
                                }
                                {
                                    (message.feedBack) ?
                                    <>
                                        <hr class="bg-danger border-2 border-top border-dark mt-5" />
                                        <div class="right-image-box mt-2">
                                            <img src="/images/copilot-large.png" class="logo heart-beat" alt="paper image" width="41" height="auto"/>
                                        </div>
                                        <div class="chat-description mt-4">
                                            <p>{message.statusPMessage}</p>
                                        </div>
                                    </>
                                    :
                                    ""
                                }
                            </div>
                            : 
                            <div class="chat-list-box-wrap">
                                &nbsp;
                            </div>
                        }
                        { message.previewChats.map((chat, index) => (
                            (undefined !== chat.errors)?
                                "" :
                            <div class="widgetgpt-conversation-content w-100 pb-20">
                                <MessagePreview
                                    key = {chat.id}
                                    messageRec = { chat }
                                    message = {message}
                                    assistant={assistant}
                                    handleQrbClick = { this.handleQrbClick }
                                />
                            </div>
                        ))}
                        { (message.loadingPT) ?
                            <div class="chat-list-box-wrap row">
                                <div class="left-image-box">
                                    <img src="/images/co-pilot.png" class="logo" alt="paper image" width="41" height="auto"/>
                                </div>
                                <div class="chat-body-box">
                                    <div class="chat-text-box">
                                        <div class="title"><strong>{assistant.selectedGpt}</strong></div>
                                        <div class="chat-description">
                                            <span class='loading'>&#9679;</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                        }
                        { (message.feedBackPT) ?
                            <div class="chat-list-box-wrap row">
                                <div class="left-image-box">
                                    <img src="/images/co-pilot.png" class="logo" alt="paper image" width="41" height="auto"/>
                                </div>
                                <div class="chat-body-box">
                                    <div class="chat-text-box">
                                        <div class="title"><strong>{assistant.selectedGpt}</strong></div>
                                        <div class="chat-description">
                                            <Loadingstatus  message = {message}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                        }
                        <div style={{ float:"left", clear: "both" }}
                            ref={(el) => { this.messagesEnd = el; }}>
                        </div>
                  </div>
                  {
                    (disableInput) ?
                    <div class="send-chat-box">
                        <div class="chat-box-bottom">
                            <div class="chat-box-wrap">
                                <div class="input-group mb-3">
                                    <strong>After you create and configure your Inbound GPT using AI, you can test your Inbound GPT in the preview tab. </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div class="send-chat-box">
                        <div class="chat-box-bottom">
                            <div class="chat-box-wrap">
                                <div class="input-group mb-3">
                                    <input  accept=".doc,.docx,.pdf,.txt,.rtf" type="file" class="form-control" id="inputGroupFile02" style={{ display: "none"}}/>
                                    <label class="input-group-text clip-icon-box" for="inputGroupFile02"><img src="/images/paper.png" class="paper-img" alt="paper image" width="20" height="auto"/></label>
                                    <textarea ref={(node) => {this.input = node}} onKeyUp={this.handleKeyPress}  onChange={this.handleChange} disabled={disableInput} class="form-control" placeholder="Message Inbound GPT" id="exampleFormControlTextarea1" rows="3"></textarea>
                                    <button onClick={this.sendButtonClick} class={sendButtonStatus}  disabled={disableInput}  data-testid="send-button"><span class="" data-state="closed">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="text-white dark:text-black"><path d="M7 11L12 6L17 11M12 18V7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                  }
                 
              </div>
          </div>
        );
    }
    render() {
        return this.desktopPreviewTab(this.props);
    }
}
 

export default DesktopPreviewTab;
