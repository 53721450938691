import React, { Component } from "react";
import GoogleloginBtn from '../google_login';
import { API_SERVER } from '../../constants/env';
class Login extends Component {
    constructor(props){
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
    }
    async handleLogin(e) {
        console.log("handle fileupload called",e.target)
        e.preventDefault();
        //const server = `https://app.siteglue.ai/login`;
        const server = `${API_SERVER}/auth/`;
        const session = localStorage.getItem('key');
        let headers = new Headers();
        const formData = new FormData();
        const email = document.getElementById('login-modal-email').value;
        const pwd = document.getElementById('login-modal-pwd').value;
        formData.append("email", email);
        formData.append("session", session);
        formData.append("password",  pwd);
        console.log(server);
        document.body.style.cursor = 'wait';
        try {
            const result = await fetch(server, {
                method: "POST",
                credentials: 'include',
                body: formData,
                //headers: headers
            });
            document.body.style.cursor='default';
            const { headerEvents } = this.props;
            if (result.status == 200) {
                const data = await result.json();
                console.log(data);
                headerEvents("login", data.user);
                const loginModal = document.getElementById('loginModal');
                const modal = window.bootstrap.Modal.getInstance(loginModal)
                modal.hide();
            } else if(result.status == "401") {
                const data = await result.json();
                headerEvents("loginFailed", data.message);
            }
        } catch (e) {
            console.log(e);
        }
        return false;
    }
    login() {
        const {assistant,headerEvents } = this.props;
        return (
            <div class="modal fade login-modal" id="loginModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="loginModal" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                    <div class="modal-header border-0">
                        
                    </div>
                    <div class="modal-body px-4 pb-5">
                        <h5 class="modal-title text-center" id="exampleModalLabel">Login</h5>
                        <div class="modal-form-box pt-4">
                            <form onSubmit = { this.handleLogin } >
                                    {
                                    (assistant.login_message !='') ?
                                        <div class="alert alert-danger" role="alert">
                                        {assistant.login_message}
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    (assistant.signup_success !='') ?
                                        <div class="alert alert-success" role="alert">
                                        {assistant.signup_success}
                                        </div>
                                        :
                                        ""
                                }
                                <div class="mb-3">
                                    <input type="email" class="form-control" placeholder="Email" id="login-modal-email" required/>
                                </div>
                                <div class="mb-3">
                                    <input type="password" class="form-control" placeholder="Password" required id="login-modal-pwd"/>
                                </div>
                                <div class="mb-3 d-flex justify-content-end forgot-pwd-box">
                                    <a data-bs-toggle="modal" href="#resetPwdModal">Forgot Password</a>
                                </div>
                                <button  type="submit" class="btn btn-primary w-100">Login</button>
                            </form>
                            <div class="separator-box-main py-4">
                                <div class="separator-box"></div>
                                <div class="sep-box-text text-center"><span>Or</span></div>
                            </div>
                            <GoogleloginBtn type='login' headerEvents = {headerEvents}/>
                            <div class="pt-4 text-center bottom-link">
                                    <a data-bs-toggle="modal" href="#login-modal-v2">Sign Up</a>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return this.login(this.props);
    }
}

export default Login;