import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LeftBoxMainMenu from '../components/left_box_main_menu';
class LeftBox extends Component { 
  constructor(props){
    super(props);
    this.handleDMClick = this.handleDMClick.bind(this);
  }
  handleDMClick() {
    const { headerEvents } = this.props;
    headerEvents("removeFullwidth", '');
  }
  createLeftBox(props) {
    const { message, assistant,builderTabEvents,configureTabEvents,headerEvents,previewTabEvents,embedTabEvents } = props;
    return (
      <>
        <div class="col-md-2 left-AI-box h-100 px-0 collapse show" id="myCollapsible">
        <nav class="navbar navbar-dark bg-dark">
          <div class="container-fluid-nav">
            <button onClick={this.handleDMClick} class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#myCollapsible" aria-controls="myCollapsible" aria-expanded="true" aria-label="Toggle navigation">
            <img src='/images/IconClose.png' />

            </button>
          </div>
        </nav>
            <LeftBoxMainMenu
              message={message}
              assistant={assistant}
              builderTabEvents={builderTabEvents}
              configureTabEvents={configureTabEvents}
              headerEvents={headerEvents}
              previewTabEvents={previewTabEvents}
              embedTabEvents={embedTabEvents} />
          </div>
       
        </>
    );
  }

  render() {
    return this.createLeftBox(this.props);
  }
}

export default LeftBox;
