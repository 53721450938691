import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class AdvanceKBComponent extends Component{
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.handleUserBack = this.handleUserBack.bind(this);
    }
    handleUserBack(e){
        const { configureTabEvents } = this.props;
        configureTabEvents('showUser',false);
    }
    advanceKBComponent(props) {
        const { assistant } = props;
        let userData = {
            name: '',
            email: '',
        }
        if (assistant.teamIndex !== null && assistant.userIndex !== null) {
            userData = assistant.configureTabData.teams[assistant.teamIndex].users[assistant.userIndex];
        } else if(assistant.locationIndex !== null && assistant.userIndex !== null) {
            userData = assistant.configureTabData.locations[assistant.locationIndex].users[assistant.userIndex];
        }
        return(
            <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Order Advance knowledge base</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="location-form-box team-form-box pb-0">
                                <div class="form-wrap">
                                    <form class="h-100">
                                        <fieldset class="field-set-1">
                                            <div class="input-group mb-3">
                                                Enter your email address and we will do a deep analysis of your website and send you an advanced knowledge base you can upload. Note: Allow 3-4 hours for deep analysis and advance knowledge base.
                                            </div>
                                        </fieldset>
                                        <fieldset class="field-set-1">
                                            <label for="company-name" class="form-label float-start">Email Address</label>
                                            <div class="input-group mb-3">
                                                <input ref={this.myRef} defaultValue={userData.email} type="text" class="form-control" placeholder="" aria-label="company-name" aria-describedby="company-name"/>
                                            </div>
                                        </fieldset>
                                    
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" onClick={this.handleUserBack} class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    render() {
        return this.advanceKBComponent(this.props);
    }
}

export default AdvanceKBComponent;
