import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UsersComponent from './users';
import User from "./user";

class TeamsComponent extends Component{
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.handleBack = this.handleBack.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }
    handleFormChange(e) {
        const { assistant } = this.props;
        if (assistant.configureTabData.teams.length > 0 && assistant.teamIndex !== null) {
            assistant.configureTabData.teams[assistant.teamIndex]['name'] = e.target.value;
        }
    }
    handleBack(e){
        const { configureTabEvents } = this.props;
        configureTabEvents('showTeam',false);
    }
    teamsComponent(props) {
        const { assistant,message,configureTabEvents,handleUserClick } = props;
        let teamData = {
            name:'',
            users: [],
        }
        if (assistant.configureTabData.teams.length > 0 && assistant.teamIndex !== null) {
            const selectedTeam = assistant.configureTabData.teams[assistant.teamIndex];
            teamData = selectedTeam;
        } else {
            let index = assistant.configureTabData.teams.length;
            assistant.teamIndex = index;
            assistant.configureTabData.teams[assistant.teamIndex] = teamData;
        }
        const teamClicked = {yes: true, no: false}
        return(
            <div class="tab-pane fade active show" id="leftTab2" role="tabpanel" aria-labelledby="leftTab2-tab">
            <div class="form-box location-form-box team-form-box pb-0">
                <div class="form-wrap h-100">
                    <form class="h-100">
                        <fieldset class="field-set-1 text-fieldset">
                            <div class="input-group mb-5">
                                    <div className="back-button-box">
                                        <a onClick={this.handleBack} href="javascript:;"><i className="fa fa-chevron-left"></i></a>
                                    </div>
                                    <h4 class="text-center mb-4">
                                        Add Team
                                    </h4>
                                    <small class="text-center">Let your inbound GPT route notifications to the right person on your team</small>
                            </div>
                        </fieldset>
                        <fieldset class="field-set-1">
                            <label for="company-name" class="form-label">Team Name</label>
                            <div class="input-group mb-3">
                                <input onChange={this.handleFormChange} id='team-name' ref={this.myRef} defaultValue={teamData.name} type="text" class="form-control" placeholder="" aria-label="company-name" aria-describedby="company-name"/>
                            </div>
                        </fieldset>
                        
                        <fieldset class="field-set-4">
                            <h4 class="form-label text-center">Add Users </h4>
                            <small class="text-center d-block w-100 mb-4">Let your Inbound GPT send email notifications to these users</small>
                            { teamData.users.map((user, index) => (
                                    <User 
                                        key = {index} 
                                        index = { index } 
                                        assistant = {assistant}
                                        user = {user}
                                        handleUserClick = {handleUserClick}
                                    />
                                ))}
                            <button class="btn btn-outline-secondary" type="button" id="button-addon1" data-bs-toggle="modal" data-bs-target="#exampleModal">Add user</button>
                        </fieldset>
                    </form>
                </div>
            </div>
            <UsersComponent 
                    message={message}
                    teamClicked= { teamClicked }
                    assistant= {assistant} 
                    configureTabEvents = {configureTabEvents}
                />
        </div>
        );
    }
    render() {
        return this.teamsComponent(this.props);
    }
}

export default TeamsComponent;
