import { 
    selectAssistant,
    removeFullwidth,
    addFullwidth,
    logout,
    login,
    loginFailed,
    passwordMisMatch,
    signupErr,
    signup,
    loginShown,
    showSplashScreen,
    resetErr,
    reset
} from '../actions/index';
class HeaderEvents  {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }
    static getInstance(dispatch) {
        if (!HeaderEvents.instance) {
            HeaderEvents.instance = new HeaderEvents(dispatch);
        }
        return HeaderEvents.instance; 
    }
    selectGptAgent(param){
        console.log(param);
        this.dispatch(selectAssistant(param));
    }
    dispatchBuilderFile(param) {
    
    }
    dispatchEnableConfigureTab (param) {
    
    }
    addFullwidth (param) {
        this.dispatch(addFullwidth());
    }
    removeFullwidth (param) {
        this.dispatch(removeFullwidth());
    }
    login(param = {}){
        this.dispatch(login(param));
    }
    loginFailed (param) {
        this.dispatch(loginFailed(param));
    }
    logout(param){
        this.dispatch(logout(param));
    }
    passwordMisMatch(param){
        this.dispatch(passwordMisMatch(param));
    }
    signup(param){
        this.dispatch(signup(param));
    }
    signupErr(param) {
        this.dispatch(signupErr(param));
    }
    loginShown(param) {
        this.dispatch(loginShown(param));
    }
    showSplashScreen (param) {
        this.dispatch(showSplashScreen(param));
    }
    reset (param) {
        this.dispatch(reset(param));
    }
    resetErr (param) {
        this.dispatch(resetErr(param));
    }
}
export default HeaderEvents;