import { 
    typingStartPT,
    addMessagePT,
    uploadSucess,
    uploadError,
    clearuploadMsg,
    qrbClicked,
} from '../actions/index';
class PreviewTabEvents  {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }
    static getInstance(dispatch) {
        if (!PreviewTabEvents.instance) {
            PreviewTabEvents.instance = new PreviewTabEvents(dispatch);
        }
        return PreviewTabEvents.instance; 
    }
    dispatchMessageInput(param){
        this.dispatch(typingStartPT(param));
    }
    dispatchMessageSent(param) {
        const session = localStorage.getItem('key');
        param.session = session;
        this.dispatch(addMessagePT(param));
    }
    fileUploadSuccess(param) {
        this.dispatch(uploadSucess(param));
    }
    fileUploadError(param){
        this.dispatch(uploadError(param));
    }
    clearUploadMsg(param){
        this.dispatch(clearuploadMsg(param));
    }
    qrbClicked(param){
        this.dispatch(qrbClicked(param));
    }
    dispatchEnableConfigureTab (param) {
    
    }
}
export default PreviewTabEvents;