import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LeftTabListItems from "../components/left_tab_list_items";
import LeftTabContent from "./left_tab_content";

class LeftTabslist extends Component { 
  
  createLeftTabslist(props) {
    const { message, assistant,builderTabEvents,configureTabEvents,headerEvents,previewTabEvents,embedTabEvents } = props;
    return (
      <div class="col-md-6 h-100 pt-2 pb-5 pe-0 d-flex flex-column">
        <LeftTabListItems 
          message = { message}
          assistant= {assistant} 
          builderTabEvents = {builderTabEvents}
          configureTabEvents = {configureTabEvents}
          headerEvents = {headerEvents}
          previewTabEvents = {previewTabEvents}
          embedTabEvents = {embedTabEvents}
        />
        <LeftTabContent
          message = { message}
          assistant= {assistant} 
          builderTabEvents = {builderTabEvents}
          configureTabEvents = {configureTabEvents}
          headerEvents = {headerEvents}
          previewTabEvents = {previewTabEvents}
          embedTabEvents = {embedTabEvents}
        />
      </div>
    );
  }

  render() {
    return this.createLeftTabslist(this.props);
  }
}

export default LeftTabslist;