const initialState = {
    fetching: false,
    chats: [],
    previewChats: [],
    extraChats : [],
    loading: false,
    loadingPT: false,
    feedBackPT:false,
    dtime:null,
    feedBack:false,
    inputDisabled: false,
    inputEnabled: true,
    qrb_clicked: false,
    locations: true,
    selectList: [],
    clicked: false,
    streaming: false,
    typingCreateTab: false,
    inputBox2: 'non-active',
    statusMessage: '',
    statusPercent : '',
    typingPTab: false,
    stopSend: false,
    website_url: '',
    saveButton: false,
    buildStarted: false,
    statusPMessage: '',
  };
  const message = (state = initialState, action) => {
    let oldChats = state.chats;
    let oldPChats = state.previewChats;
    switch (action.type) {
      case 'ADD_MESSAGE_CT':
        return { ...state,
          chats: oldChats.concat([{
            message: action.message,
            author: action.author,
            session: action.session,
            id: action.id,
          }]),
          loading: true,
          feedBack: state.feedBack,
          typingCreateTab: false,
          stopSend: true,
        };
      case 'MESSAGE_RECEIVED_CT':
        return { ...state,
          chats: oldChats.concat([{
            message:action.message,
            author: action.author,
            extraChatsCount:action.extraChatsCount,
            id:action.id,
            dtime:action.dtime,
          }]),
          loading: false,
          feedBack: false,
          stopSend: false,
        };
      case 'ADD_MESSAGE_PT':
        if (action.message != "show_default_welcome") {
          return {
            ...state,
            previewChats: oldPChats.concat([{
              message: action.message,
              author: action.author,
              session: action.session,
              id: action.id,
            }]),
            loadingPT: true,
            feedBackPT: state.feedBack,
            typingCreateTab: false,
            loading: false,
          };
        } else { 
          return state
        }
      case 'MESSAGE_RECEIVED_PT':
        return { ...state,
          previewChats: oldPChats.concat([{
            message:action.message,
            author: action.author,
            extraChatsCount:action.extraChatsCount,
            id:action.id,
            dtime:action.dtime,
          }]),
          loadingPT: false,
          feedBackPT: false,
        };  
      case  'MESSAGE_WILL_BE_RECEIVED':
       if (state.chats.length > 0) {
          const count = state.chats.length -1;
          const last_message = state.chats[count]
          //if (last_message.author == 'Me') showCXText = true
        }
        return { ...state,
          loading: true,
        };
      
      case 'ADD_MESSAGE_STREAM_PT':
       if (state.previewChats.length > 0) {
          const count = state.previewChats.length -1;
          //const last_message = state.chats[count];
          if (state.previewChats[count].author == 'assistant') {
            state.previewChats[count].message= action.message;
          } else {
            state.previewChats.push({
              message:action.message,
              author: action.author,
              session: action.session,
              extraChatsCount:0,
              id:action.id,
              dtime:action.dtime,
              clicked: false,
              lmore: action.lmore,
            });
          }
        } else if(state.previewChats.length === 0) {
          state.previewChats.push({
            message:action.message,
            author: action.author,
            session: action.session,
            extraChatsCount:0,
            id:action.id,
            dtime:action.dtime,
            clicked: false,
            lmore: action.lmore,
          });
        }
        return { ...state,
          loadingPT: false,
          errors:action.errors,
          streaming: true,
          feedBack: action.feedBack,
          showCXText: false,
        };
      case 'ADD_MESSAGE_STREAM_CT':
        console.log('action ADD_MESSAGE_STREAM_CT', action);
        if (state.chats.length > 0) {
          const count = state.chats.length -1;
          //const last_message = state.chats[count];
          if (state.chats[count].author == 'assistant') {
            state.chats[count].message= action.message;
          } else {
            state.chats.push({
              message:action.message,
              author: action.author,
              session: action.session,
              extraChatsCount:0,
              id:action.id,
              dtime:action.dtime,
              clicked: false,
              lmore: action.lmore,
            });
          }
        }
        const x = /click/i.test(action.message);
        const y = /SAVE/i.test(action.message);
        const z = /below/i.test(action.message);
        let saveButton = false;
        if (x && y && z) saveButton = true
        return { ...state,
          loading: false,
          errors:action.errors,
          streaming: true,
          feedBack: false,
          showCXText: false,
          stopSend: false,
          saveButton
        };
      case 'TYPING_CREATE_TAB':
        return {...state, typingCreateTab: action.status}
      case 'TYPING_P_TAB':
          return {...state, typingPTab: action.status}
      case 'QRB_CLICK':
        return {...state, qrb_clicked: true, website_url:action.website_url, saveButton: false}
      case 'RUNRESUMED':
        return {...state, statusMessage:action.statusMessage,statusPercent:'75',feedBack:true,loading:false, statusPMessage: action.statusPMessage}
      case 'RUNCOMPLETE':
        return {...state, statusMessage:action.statusMessage,statusPercent:'90', statusPMessage: action.statusPMessage}
      case 'THREADMESSAGECREATE':
        return {...state, feedBack:false,loading:true}
      case 'BROWSINGWEB':
        return {...state, statusMessage:action.statusMessage,statusPercent:'50',feedBack:true,loading:false, statusPMessage: action.statusPMessage}
      case 'MESSAGE_RUN_CREATED':
        return {...state, feedBack:false, loading:true};
      case 'BUILD_STARTED':
        return {...state, buildStarted: true};
      case 'SETUPCOMPLETE':
          return {...state, buildStarted:false, }
      default:
        return state
    }
  }

  export default message;