import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class InstructionsComponent extends Component{
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.handleBack = this.handleBack.bind(this);
    }
    handleBack(e){
        const { configureTabEvents } = this.props;
        configureTabEvents('showInstruction',false);
    }
    instructionsComponent(props) {
        const { assistant } = props;
         return(
            <div class="tab-pane fade active show" id="leftTab2" role="tabpanel" aria-labelledby="leftTab2-tab">
                <div class="form-box location-form-box">
                    <div class="form-wrap">
                        <form>
                            <fieldset class="field-set-0 text-fieldset">
                                <div class="input-group mb-5">
                                    <div className="back-button-box">
                                        <a onClick={this.handleBack} href="javascript:;"><i className="fa fa-chevron-left"></i></a>
                                    </div>
                                    <h4 class="text-center mb-4">
                                        Update Instructions (Advanced users)
                                    </h4>
                                    <small class="text-center">Update your inbound GPT knowledge base with basic information from your website, text files uploaded from your computers or order an advanced knowledge base to get a deep analysis of your website.</small>
                                </div>
                            </fieldset>
                            <fieldset class="field-set-1">
                                <label for="company-name" class="form-label">Inbound GPT instructions</label>
                                <div class="input-group mb-3">
                                    <textarea ref={this.myRef} defaultValue={assistant.configureTabData.assistant_instruction} class="form-control" id="exampleFormControlTextarea1" rows="15"/>
                                </div>
                            </fieldset>
                            
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    render() {
        return this.instructionsComponent(this.props);
    }
}

export default InstructionsComponent;
