import React, { Component } from 'react';
class Team extends Component { 
    team (props) {
        const {assistant, team, handleTeamClick, index } = props;
        return (
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder={team.name} aria-label="Username" aria-describedby="users-text1"/>
                <button type="button"  onClick={handleTeamClick} data-index={index} class="input-group-text" id="users-text1"><i class="fa fa-cog"></i></button>
            </div>
        );
    }
    render() {
        return this.team(this.props);
    }
}

export default Team;
