import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AdvanceKBComponent from './advancekb';

class KbOptionsComponent extends Component{
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.handleBack = this.handleBack.bind(this);
    }
    handleBack(e){
        const { configureTabEvents } = this.props;
        configureTabEvents('showKb',false);
    }
    kbOptionsComponent(props) {
        const { assistant,message,configureTabEvents } = props;
         return(
            <div class="tab-pane fade active show" id="leftTab2" role="tabpanel" aria-labelledby="leftTab2-tab">
                <div class="form-box location-form-box">
                    <div class="form-wrap">
                        <form>
                            <fieldset class="field-set-0 text-fieldset">
                                <div class="input-group mb-5">
                                    <div className="back-button-box">
                                        <a onClick={this.handleBack} href="javascript:;"><i className="fa fa-chevron-left"></i></a>
                                    </div>
                                    <h4 class="text-center mb-4">
                                        Update Knowledge 
                                    </h4>
                                    <small class="text-center">Update your inbound GPT knowledge base with basic information from your website, text files uploaded from your computer or order an advance knowledge base to get a deep analysis of your website content.</small>
                                </div>
                            </fieldset>
                            <fieldset class="field-set-1">
                                <label for="url_address" class="form-label">Basic knowledge base</label>
                                <div class="input-group mb-3">
                                    <input ref={this.myRef} defaultValue={assistant.configureTabData.url_address} type="text" class="form-control" placeholder="" aria-label="url_address" aria-describedby="url_address"/>
                                </div>
                            </fieldset>
                            <fieldset class="field-set-1">
                                <div class="input-group mb-3">
                                    <textarea ref={this.myRef} defaultValue={assistant.configureTabData.web_data} class="form-control" id="exampleFormControlTextarea1" rows="15"/>
                                </div>
                            </fieldset>
                            <fieldset class="field-set-1">
                                <label for="company-name" class="form-label">Upload Files</label>
                                <div class="input-group mb-3">
                                    <button  class="btn btn-outline-secondary" type="button" id="button-addon1">Upload Files</button>
                                </div>
                                <div class="input-group mb-3">
                                    <input ref={this.myRef} type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="Conversation-text3"/>
                                    <span class="input-group-text" id="Conversation-text3"><i class="fa fa-times"></i></span>
                                </div>
                                <div class="input-group mb-3">
                                    <input ref={this.myRef}  type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="Conversation-text4"/>
                                    <span class="input-group-text" id="Conversation-text4"><i class="fa fa-times"></i></span>
                                </div>
                            </fieldset>
                            <fieldset class="field-set-1">
                                <label for="company-name" class="form-label">Advanced knowledge base</label>
                                <div class="pull-right-button">
                                    <button class="btn btn-outline-secondary" type="button" id="button-addon1" data-bs-toggle="modal" data-bs-target="#exampleModal2">Order Advance knowledge</button>
                                </div>
                                <div class="input-group mb-3">
                                <button  class="btn btn-outline-secondary" type="button" id="button-addon1">Upload Advanced knowledge base</button>
                                </div>
                                <div class="input-group mb-3">
                                    <textarea ref={this.myRef} defaultValue={assistant.en_instruction} class="form-control" id="exampleFormControlTextarea1" rows="15"/>
                                </div>
                            </fieldset>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </form>
                        <AdvanceKBComponent 
                                 message = { message}
                                 assistant= {assistant} 
                                 configureTabEvents = {configureTabEvents}
                            />
                    </div>
                </div>
            </div>
        );
    }
    render() {
        return this.kbOptionsComponent(this.props);
    }
}

export default KbOptionsComponent;
