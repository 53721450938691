import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CreateTabContent from "../components/create_tab_content";
import ConfigureTabContent from "../components/configure_tab_content";
import MobilePreviewTabContent from "../components/mobile_preview_tab_content";
import MobileEmbedTabContent from "../components/mobile_embed_tab_content";

class LeftTabContent extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
  }
  render() {
    const { message, assistant,builderTabEvents,configureTabEvents,headerEvents,previewTabEvents,embedTabEvents } = this.props;
    return (
      <div class="tab-content pt-5" id="leftTabContent">
        <CreateTabContent 
          message = { message}
          assistant= {assistant} 
          builderTabEvents = {builderTabEvents}
          configureTabEvents = {configureTabEvents}
          headerEvents = {headerEvents}
          previewTabEvents = {previewTabEvents}
          embedTabEvents = {embedTabEvents}
        />
        <ConfigureTabContent message = { message}
          assistant= {assistant} 
          builderTabEvents = {builderTabEvents}
          configureTabEvents = {configureTabEvents}
          headerEvents = {headerEvents}
          previewTabEvents = {previewTabEvents}
          embedTabEvents = {embedTabEvents}/>
        <MobilePreviewTabContent
          message = { message}
          assistant= {assistant} 
          builderTabEvents = {builderTabEvents}
          configureTabEvents = {configureTabEvents}
          headerEvents = {headerEvents}
          previewTabEvents = {previewTabEvents}
          embedTabEvents = {embedTabEvents}
        />
        <MobileEmbedTabContent
          message = { message}
          assistant= {assistant} 
          builderTabEvents = {builderTabEvents}
          configureTabEvents = {configureTabEvents}
          headerEvents = {headerEvents}
          previewTabEvents = {previewTabEvents}
          embedTabEvents = {embedTabEvents}
        />
      </div>
    );
  }
}
/*const mapDispatchToProps = dispatch => ({
  dispatch: () => {
    //dispatch(messageWillbeReceived());
  },
  dispatchAddSelList: (item) => {
    //dispatch(addSelectList(item));
  },
  dispatchClickedMessage: () => {
    //dispatch(clickedMessage());
  },
  dispatchAddMessage: (text, author) => {
    const session = localStorage.getItem('key');
    //dispatch(addMessage(text, author, session, true));
    //dispatch(messageWillbeReceived());
    if (author === 'Me') {
      let counter = 1;
      let myInterval = setInterval(function() {
        if (counter == 3) {
          //dispatch (showSearchWebsiteMessage());
        } else if(counter > 5) {
          clearInterval(myInterval); 
        }
        counter++;
      }, 1000);
    }
    //dispatch(clearSelectList());
  },
  dispatchDisableInput: () => {
    //dispatch(disableUserInput()) ;
  },
  dispatchEnableCal: () => {
    //dispatch(enableCal()) ;
  },
  dispatchDisableCal: () => {
    //dispatch(disableCal()) ;
  },
});

const LeftTabContents = connect(state => ({
  message: state.message,
  assistant: state.assistant,
}), mapDispatchToProps)(LeftTabContent);*/


export default LeftTabContent;