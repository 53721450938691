import React, { Component } from "react";
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import { API_SERVER } from '../constants/env';
const authWithGoogle = async (codeResponse,type,headerEvents, navigate) => {
    const formData = new FormData();
    formData.append("code", codeResponse.code);
    let endPoint = 'auth/provider/google';
    if (type == 'signup') endPoint = 'auth/provider/google/signup';
    const server = `${API_SERVER}/${endPoint}`;
    const result = await fetch(server, {
        method: "POST",
        credentials: 'include',
        body: formData,
        //headers: headers
    });
    document.body.style.cursor='default';
    if (result.status == 200) {
        const data = await result.json();
        console.log(data);
        if (type == 'login') {
            headerEvents("login", data.user);
            const loginModal = document.getElementById('loginModal');
            const modal = window.bootstrap.Modal.getInstance(loginModal)
            modal.hide();
        } else if (type == 'signup') {
            headerEvents("signup", data.message);
            navigate("/");
            headerEvents("login", data.user);
            const signupModal = document.getElementById('login-modal-v2');
            const modal = window.bootstrap.Modal.getInstance(signupModal)
            modal.hide();
        }
    } else if(result.status == "401") {
        const data = await result.json();
        if (type == 'login') {
            headerEvents("loginFailed", data.message);
        } else if (type == 'signup') {
            headerEvents("signupErr", data.message);
        }
    }
};

const GoogleloginBtn = (props) => {
    const { type, headerEvents} = props;
    const navigate = useNavigate();
    const login = useGoogleLogin({
        onSuccess: codeResponse => authWithGoogle(codeResponse,type,headerEvents, navigate),
        flow: 'auth-code',
    });
    return (
        <div class="google-btn-box">
            <button type="submit" class="btn btn-primary w-100" onClick={() => login()}>
                <svg style = {{ marginRight: "10px", marginTop: "-3px"}} width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M17.64 9.205c0-.639-.057-1.252-.164-1.841H9v3.481h4.844a4.14 4.14 0 0 1-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615z" fill="#4285F4" fill-rule="nonzero"></path><path d="M9 18c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H.957v2.332A8.997 8.997 0 0 0 9 18z" fill="#34A853" fill-rule="nonzero"></path><path d="M3.964 10.71A5.41 5.41 0 0 1 3.682 9c0-.593.102-1.17.282-1.71V4.958H.957A8.996 8.996 0 0 0 0 9c0 1.452.348 2.827.957 4.042l3.007-2.332z" fill="#FBBC05" fill-rule="nonzero"></path><path d="M9 3.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.463.891 11.426 0 9 0A8.997 8.997 0 0 0 .957 4.958L3.964 7.29C4.672 5.163 6.656 3.58 9 3.58z" fill="#EA4335" fill-rule="nonzero"></path><path d="M0 0h18v18H0z"></path></g></svg>
                Continue With Google
            </button>
        </div>
    )
}
export default GoogleloginBtn;