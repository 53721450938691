import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function LeftBoxMainMenu() {
  return (
    <><div class="top-image-box pt-3">
      <a href="javascript:;"><img src="/images/Color logo - no background.png" class="logo" alt="paper image" width="200" height="auto" /></a>
    </div><div class="links-box pt-5">
        <ul class="top">
          <li class="active"><a target="__blank" href="https://easygpt.builders/gptbuilders/">Easy GPT Builders</a></li>
          <li><a target="__blank" href="https://easygpt.builders/features/">Features</a></li>
          <li><a target="__blank" href="https://easygpt.builders/usecases/">Case Studies</a></li>
          <li><a target="__blank" href="https://easygpt.builders/about/">About Us</a></li>
        </ul>
        <ul class="bottom">
          <li class="active"><a href="javascript:;">Contact Us</a></li>
          <li><a target="__blank" href="https://easygpt.builders/affiliates">Affiliates</a></li>
          <li><a target="__blank" href="https://support.easygpt.builders">Support</a></li>
          <li><a target="__blank" href="https://easygpt.builders/privacy/">Privacy Policy</a></li>
          <li><a target="__blank" href="https://easygpt.builders/terms/">Terms & Conditions</a></li>
        </ul>
      </div></>
  );
}

export default LeftBoxMainMenu;