import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Message from "./message";
import Loadingstatus from "./loading_status";
import { API_SERVER } from '../constants/env';
class CreateTabContent extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleQrbClick = this.handleQrbClick.bind(this);
        this.sendButtonClick = this.sendButtonClick.bind(this);
        this.handleGPTSelClick = this.handleGPTSelClick.bind(this);
        this.input = '';
        this.website_url = '';
        this.messagesEnd = null;
    }
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
    
    componentDidMount() {
        const { assistant } = this.props;
        if (assistant.showCreateTab)
            this.scrollToBottom();
    }

    componentDidUpdate() {
        const { assistant } = this.props;
        if (assistant.showCreateTab)
            this.scrollToBottom();
    }
    handleGPTSelClick(e) {
        const { headerEvents } = this.props;
        console.log('the input message typed is ', e.target.getAttribute('data-item') );
        const m = e.target.getAttribute('data-item');
        console.log(m.length);
        headerEvents("selectGptAgent", m);
        const inboundModal = new window.bootstrap.Modal(document.getElementById('inboundModal'), {});
        inboundModal.toggle();
    }
    handleChange(e) {
        //this.setState({ value: e.target.value });
        const { builderTabEvents } = this.props;
        console.log('the input message typed is ', e.target.value );
        const m = e.target.value;
        console.log(m.length);
        if (m.length > 0) builderTabEvents("dispatchMessageInput", true);
        else builderTabEvents("dispatchMessageInput", false);
    }
    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.addMessage();
        }
    }
    sendButtonClick(e){
        this.addMessage();
    }
    async handleFileUpload(e) {
        console.log("handle fileupload called",e.target.files)
        if (e.target.files) {
            //setFile(e.target.files[0]);
            const { builderTabEvents } = this.props;
            const file = e.target.files[0];
            const session = localStorage.getItem('key');
            const formData = new FormData();
            formData.append("file", file);
            formData.append("session", session);
            try {
                // You can write the URL of your server or any other endpoint used for file upload
                document.body.style.cursor='wait';
                const server = `${API_SERVER}/uploadFile`;
                //const server = `${document.location.protocol}//localhost:8082/upload_file`;
                let headers = new Headers();

                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');

                headers.append('Access-Control-Allow-Origin', `${API_SERVER}`);
                const result = await fetch(server, {
                  method: "POST",
                  body: formData,
                  headers: headers
                });
                document.body.style.cursor='default';
                const data = await result.json();

                console.log(data);
                builderTabEvents("fileUploadSuccess", 'File contents  added to assitant.');
                setTimeout(()=>{
                    builderTabEvents("clearUploadMsg", '');
                },3000);
            } catch (error) {
                console.error(error);
                document.body.style.cursor='default';
                const { builderTabEvents } = this.props;
                builderTabEvents("fileUploadError", "Unable to send! try again later.");
                setTimeout(()=>{
                    builderTabEvents("clearUploadMsg", '');
                },3000);
            }
        }
    }
    handleQrbClick(e){
        const text = e.currentTarget.getAttribute('data-text');
        const { builderTabEvents, message } = this.props;
        if (text != null && text != '') {
            //const webiste_url = document.getElementById('website_url').value;
            if (!message.stopSend) {
                builderTabEvents("dispatchMessageSent", {
                    message: text,
                    author: 'Me',
                    feedback: true,
                });
                builderTabEvents("qrbClicked", '');
            }
        } else if (e.target.innerHTML === 'Configure') {
            const { previewTabEvents } = this.props;
            document.getElementById('leftTab2-tab').click();
            builderTabEvents("enablePreviewTab", true);
            previewTabEvents("dispatchMessageSent", {
                message: "show_default_welcome",
                author: 'Me',
                feedback: true,
            });

        } else if (e.target.nodeName === "BUTTON" || e.target.nodeName === "A") {
            builderTabEvents("dispatchMessageSent", {
                message: e.target.innerHTML,
                author: 'Me',
                feedback: true,
            });
        }
    }
    addMessage(){
        const { builderTabEvents, message } = this.props;
        if(this.input.value.length > 0 &&  !message.stopSend ){
            builderTabEvents("dispatchMessageSent", {
                message: this.input.value,
                author: 'Me',
                feedback: true,
            });
            this.input.value = '';
        }
    }
    createTabContent(props) {
        const { message, assistant } = this.props;
        let enableTxtInput = true;
        if (assistant.loadedState) enableTxtInput = false;
        let sendButtonStatus = "text-white p-0.5 border border-black rounded-lg transition-colors";
        if (assistant.loadedState && message.typingCreateTab) sendButtonStatus = "text-white p-0.5 border border-black rounded-lg transition-colors active";
        return (
          <div class="tab-pane fade show active h-100" id="leftTab1" role="tabpanel" aria-labelledby="leftTab1-tab">
              <div class="create-chat-box h-100">
                <div class="chat-list-box chat-list-box-create-top">
                        <div class="chat-list-box-wrap row">
                            <div class="chat-body-box chat-body-box-v2">
                                <div class="chat-text-box d-flex align-items-center">
                                    <button class="circle-plus-btn" >+</button>

                                    <h6>
                                        Create Custom GPT
                                    </h6>
                                    <div class="dropdown">
                                        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButtonHeader" data-bs-toggle="dropdown">
                                            {assistant.selectedGpt}
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-header" aria-labelledby="dropdownMenuButtonHeader">
                                            <li className={(assistant.selectedGpt == 'Inbound GPT Builder')? 'active':''}><a onClick={this.handleGPTSelClick} data-item="Inbound GPT Builder" class="dropdown-item dropdown-item-header" ><span><i class="fa fa-check"></i></span>Inbound GPT Builder</a></li>
                                            <li className={(assistant.selectedGpt == 'Front Desk GPT Builder')? 'active':''}><a onClick={this.handleGPTSelClick} data-item="Front Desk GPT Builder"  class="dropdown-item dropdown-item-header" href="#"><span><i class="fa fa-check"></i></span>Front Desk GPT Builder</a></li>
                                            <li className={(assistant.selectedGpt == 'Help Desk GPT Builder')? 'active':''}><a onClick={this.handleGPTSelClick} data-item="Help Desk GPT Builder"  class="dropdown-item dropdown-item-header" href="#"><span><i class="fa fa-check"></i></span>Help Desk GPT Builder</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="bg-danger border-2 border-top border-dark mt-5" />
                    </div>
                  { (assistant.showCreateTab)?
                    <>
                    <div class="chat-list-box">
                      { message.chats.map((chat, index) => (
                            (undefined !== chat.errors)?
                            "": <Message 
                                  key={chat.id}
                                  messageRec={chat}
                                  message={message}
                                  assistant={assistant}
                                  handleQrbClick = { this.handleQrbClick }
                                />
                        ))}
                        { (message.loading) ?
                            <div class="chat-list-box-wrap row">
                                <div class="left-image-box">
                                    <img src="/images/co-pilot.png" class="logo" alt="paper image" width="41" height="auto"/>
                                </div>
                                <div class="chat-body-box">
                                    <div class="chat-text-box">
                                        <div class="title"><strong>{assistant.selectedGpt}</strong></div>
                                        <div class="chat-description">
                                            <span class='loading'>&#9679;</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                        }
                        { (message.feedBack) ?
                            <div class="chat-list-box-wrap row">
                                <div class="left-image-box">
                                    <img src="/images/co-pilot.png" class="logo" alt="paper image" width="41" height="auto"/>
                                </div>
                                <div class="chat-body-box">
                                    <div class="chat-text-box">
                                        <div class="title"><strong>{assistant.selectedGpt}</strong></div>
                                        <div class="chat-description">
                                            <Loadingstatus  message = {message}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                        }
                        <div style={{ float:"left", clear: "both" }}
                            ref={(el) => { this.messagesEnd = el; }}>
                        </div>
                    </div>
                    {
                        (message.qrb_clicked)?
                            <button disabled={enableTxtInput} class="custom-button" onClick={this.handleQrbClick} data-text='Build my Inbound GPT now'>
                            Build my Inbound GPT now
                            <span class="arrow"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="icon-sm text-token-text-primary"><path d="M7 11L12 6L17 11M12 18V7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>
                            </button>

                        :
                        ""
                    }
                    {
                        (message.saveButton)?
                            <button class="custom-button" onClick={this.handleQrbClick} data-text='Save'>
                            Save Now!
                            <span class="arrow"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="icon-sm text-token-text-primary"><path d="M7 11L12 6L17 11M12 18V7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>
                            </button>

                        :
                        ""
                    }

                <div class="send-chat-box">
                    <div class="chat-box-bottom">
                        <div class="chat-box-wrap">
                            <div class="input-group mb-3">
                                <input disabled={assistant.uploaded}  accept=".doc,.docx,.pdf,.txt,.rtf" onChange={this.handleFileUpload} type="file" class="form-control" id="inputGroupFile02" style={{display: "none"}}/>
                                <label class="input-group-text clip-icon-box" for="inputGroupFile02"><img src="/images/paper.png" class="paper-img" alt="paper image" width="20" height="auto"/></label>
                                <textarea  ref={(node) => {this.input = node}} onKeyUp={this.handleKeyPress}  onChange={this.handleChange} disabled={enableTxtInput} class="form-control" placeholder="Message Inbound GPT Builder" id="exampleFormControlTextarea1" rows="3"></textarea>
                                <button onClick={this.sendButtonClick} class={sendButtonStatus} data-testid="send-button"><span class="" data-state="closed">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="text-white dark:text-black"><path d="M7 11L12 6L17 11M12 18V7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>
                                </button>
                            </div>
                        </div>
                    </div>
            </div>
            </>
             :
             ""
            }
              </div>
          </div>
        );
    }
    render() {
        return this.createTabContent(this.props);
    }
}

export default CreateTabContent;
