import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css';
import App from './App';
import Verify from './Verify';
import Reset from './Reset';
//import reportWebVitals from './reportWebVitals';
import reducers from './reducers'
import rootSaga from './sagas'
import setupSocket from './observers/socket';
import {BrowserRouter, Routes, Route } from 'react-router-dom';


const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  reducers,
  applyMiddleware(
    //formValidationMiddleware,
    //loggerMiddleware,
    sagaMiddleware
  )
);
const root = ReactDOM.createRoot(document.getElementById('root'));
let socket = setupSocket(store.dispatch, store);
const dispatch = store.dispatch;
sagaMiddleware.run(rootSaga, { socket, dispatch, store })
console.log('store root App', store.getState());
root.render(
  <GoogleOAuthProvider clientId="944724694723-nm607fbqnd78rjirrcquhb8q8c86pfsf.apps.googleusercontent.com">
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/auth/verify" element={<Verify />} />
            <Route path="/auth/reset" element={<Reset />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();