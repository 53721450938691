import React, { Component } from 'react';
class User extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }
    user (props) {
        const {assistant, user, handleUserClick, index} = props;
        return (
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder={user.name} aria-label="Username" aria-describedby="users-text1"/>
                <button type="button" data-index={index}  onClick={handleUserClick} class="input-group-text" id="users-text1" data-bs-toggle="modal" data-bs-target="#exampleModal"><i class="fa fa-cog"></i></button>
            </div>
        );
    }
    render() {
        return this.user(this.props);
    }
}

export default User;