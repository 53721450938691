import React, { Component } from "react";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import './App.css';
import  LeftBox from './containers/left_box';
import  RightBox from './containers/right_box';
import BuilderTabEvents from "./listeners/builderTabEvents";
import HeaderEvents from "./listeners/HeaderEvents";
import ConfigureEvents from './listeners/ConfigureEvents'
import PreviewTabEvents from './listeners/previewTabEvents';
import { API_SERVER } from './constants/env';
import assistant from "./reducers/assistant";
class Reset extends Component {
  async componentDidMount() {
    const {navigation, assistant} = this.props;
    const modalShown = document.getElementById('resetLinkModal');
    const modal = window.bootstrap.Modal.getInstance(modalShown)
    if (!modal) {
      const resetModal = new window.bootstrap.Modal(document.getElementById('resetLinkModal'), {});
      resetModal.toggle();
    }
    if (assistant.reset_message_success !="")
      navigation("/");
  }
  render() {
    console.log("from APP",this.props )
    const { message, assistant,builderTabEvents,configureTabEvents,headerEvents,previewTabEvents,embedTabEvents } = this.props;
    return (
      <div class="row h-100">
        {
          (assistant.uploadSucess) ? 
            <div class="alert alert-success" role="alert">
              {assistant.uploadMsg}
            </div>
            :
            ""
        }
        {
          (assistant.uploadError) ?
            <div class="alert alert-danger" role="alert">
              {assistant.uploadMsg}
            </div>
            :
            ""
        }
        {
          (assistant.loading) ?
            <>
              <LeftBox
              message = { message}
              assistant= {assistant}
              builderTabEvents = {builderTabEvents}
              configureTabEvents = {configureTabEvents}
              headerEvents = {headerEvents}
              previewTabEvents = {previewTabEvents}
              embedTabEvents = {embedTabEvents}
            />
            <RightBox
              message = { message}
              assistant= {assistant}
              builderTabEvents = {builderTabEvents}
              configureTabEvents = {configureTabEvents}
              headerEvents = {headerEvents}
              previewTabEvents = {previewTabEvents}
              embedTabEvents = {embedTabEvents}
            />
              <div class="splash-wrapper">
              </div>
              <div class="splash-overlay"></div>
              <div class="splash-spanner splash-show">
                <div class="splash-loader"></div>
              </div>
            </>
          :
            <>
              <LeftBox
                message = { message}
                assistant= {assistant}
                builderTabEvents = {builderTabEvents}
                configureTabEvents = {configureTabEvents}
                headerEvents = {headerEvents}
                previewTabEvents = {previewTabEvents}
                embedTabEvents = {embedTabEvents}
                />
              <RightBox
                message = { message}
                assistant= {assistant}
                builderTabEvents = {builderTabEvents}
                configureTabEvents = {configureTabEvents}
                headerEvents = {headerEvents}
                previewTabEvents = {previewTabEvents}
                embedTabEvents = {embedTabEvents}
              />
          </>
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  /*dispatch: (subType,bot_id) => {
    setTimeout(function(){
      dispatch(initCall(subType,bot_id))
    },1000);
  }*/
  builderTabEvents: (event, data) => {
    const builderTabEvents = BuilderTabEvents.getInstance(dispatch);
    builderTabEvents[event](data);
  },
  configureTabEvents: (event, data) => {
    const configureEvent = ConfigureEvents.getInstance(dispatch);
    configureEvent[event](data);
  },
  headerEvents: (event, data) =>{
    const headerEvent =  HeaderEvents.getInstance(dispatch);
    headerEvent[event](data);
  },
  previewTabEvents: (event, data) =>{
    const previewEvent = PreviewTabEvents.getInstance(dispatch);
    previewEvent[event](data);
  },
  embedTabEvents: () => {}
})

Reset = connect(state => ({
  assistant: state.assistant,
  message: state.message,
}), mapDispatchToProps)(Reset);

export default function (props) {
  const navigation = useNavigate();

  return <Reset {...props} navigation={navigation} />;
}