import { 
    typingStartCT,
    addMessageCT,
    uploadSucess,
    uploadError,
    clearuploadMsg,
    qrbClicked,
    enablePreviewTab,
    showCreateTab
} from '../actions/index';
class BuilderTabEvents  {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }
    static getInstance(dispatch) {
        if (!BuilderTabEvents.instance) {
            BuilderTabEvents.instance = new BuilderTabEvents(dispatch);
        }
        return BuilderTabEvents.instance; 
    }
    dispatchMessageInput(param){
        this.dispatch(typingStartCT(param));
    }
    dispatchMessageSent(param) {
        const session = localStorage.getItem('key');
        param.session = session;
        this.dispatch(addMessageCT(param));
    }
    showCreateTab(param) {
       this.dispatch(showCreateTab(param));
    }
    fileUploadSuccess(param) {
        this.dispatch(uploadSucess(param));
    }
    fileUploadError(param){
        this.dispatch(uploadError(param));
    }
    clearUploadMsg(param){
        this.dispatch(clearuploadMsg(param));
    }
    qrbClicked(param){
        this.dispatch(qrbClicked(param));
    }
    enablePreviewTab (param) {
        this.dispatch(enablePreviewTab(param));
    }
}
export default BuilderTabEvents;