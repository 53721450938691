import React, { Component } from 'react';
class Location extends Component { 
    location (props) {
        const {assistant, location, handleLocationClick, index} = props;
        return (
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder={location.name} aria-label="Username" aria-describedby="users-text1"/>
                <button type="button" onClick={handleLocationClick} data-index={index} class="input-group-text" id="users-text1"><i class="fa fa-cog"></i></button>
            </div>
        );
    }
    render() {
        return this.location(this.props);
    }
}

export default Location;
