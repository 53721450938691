import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function DesktopEmbedTab() {
  return (
    <div class="tab-pane fade h-100" id="rightTab2" role="tabpanel" aria-labelledby="rightTab2-tab">
        <pre><p>Sample text here...</p><p>And another line of sample text here...</p></pre>
    </div>
  );
}

export default DesktopEmbedTab;
