import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
//disableConfigureTab
class LeftTabListItems extends Component{
    leftTabListItems(props){
        const { assistant } = props;
        let configtab = '';
        if (!assistant.disableConfigureTab) {
            configtab = 'tab';
        }
        let ptab = ''
        if (assistant.disablePreviewTab) ptab = 'tab';
        return(
            <ul class="nav nav-tabs justify-content-center border-0" id="leftTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="leftTab1-tab" data-bs-toggle="tab" href="#leftTab1" role="tab" aria-controls="leftTab1" aria-selected="true">Create</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="leftTab2-tab" data-bs-toggle={configtab} href="#leftTab2" role="tab" aria-controls="leftTab2" aria-selected="false">Configure</a>
                </li>
                <li class="nav-item for-mobile-only">
                    <a class="nav-link" id="leftTab3-tab" data-bs-toggle={ptab} href="#leftTab3" role="tab" aria-controls="leftTab3" aria-selected="false">Preview</a>
                </li>
                <li class="nav-item for-mobile-only">
                    <a class="nav-link" id="leftTab4-tab" data-bs-toggle="" href="#leftTab4" role="tab" aria-controls="leftTab4" aria-selected="false">Embed</a>
                </li>
            </ul>
        )
    }
    render() {
        return this.leftTabListItems(this.props);
    }
}

export default LeftTabListItems;