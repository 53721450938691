import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HeaderItems from "../components/header_items";
class Header extends Component { 
  
  createHeader(props) {
    const { message, assistant, headerEvents, builderTabEvents } = props;
    return (
      <header class="border-bottom">
        <HeaderItems
          message={message}
          assistant={assistant}
          headerEvents={headerEvents}
          builderTabEvents={builderTabEvents}
        />
      </header>
    );
  }

  render() {
    return this.createHeader(this.props);
  }
}

export default Header;