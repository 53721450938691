import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class UsersComponent extends Component{
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.handleUserBack = this.handleUserBack.bind(this);
        this.handleUserFormChange = this.handleUserFormChange.bind(this);
    }
    handleUserFormChange(e) {
        e.preventDefault();
        const { assistant, teamClicked } = this.props;
        let name = document.getElementById('user-name').value;
        let email = document.getElementById('user-email').value;
        const userData = {
            name,
            email,
        }
        if (teamClicked.no) {
            console.log(userData)
            const LocIndex = (assistant.locationIndex !== null) ? assistant.locationIndex : 0;
            let userIndex = (assistant.userIndex !== null) ? assistant.userIndex : 0;
            if (userIndex == 0 && assistant.configureTabData.locations?.[LocIndex]?.users) {
                userIndex = assistant.configureTabData.locations[LocIndex].users.length;
            }
            if (userIndex == -1) userIndex = 0;
            assistant.configureTabData.locations[LocIndex].users[userIndex] = userData;
        } else {
            const teamIndex = (assistant.teamIndex !== null) ? assistant.teamIndex : 0;
            let userIndex = (assistant.userIndex !== null) ? assistant.userIndex : 0;
            if (userIndex == 0 && assistant.configureTabData.teams?.[teamIndex]?.users) {
                userIndex = assistant.configureTabData.teams[teamIndex].users.length++;
                userIndex--;
            }
            assistant.configureTabData.teams[teamIndex].users[userIndex] = userData;
        }
        console.log(assistant.configureTabData);
        const userModal = document.getElementById('exampleModal');
        const modal = window.bootstrap.Modal.getInstance(userModal)
        modal.hide();
        const { configureTabEvents } = this.props;
        configureTabEvents('showUser',false);
        return false;
    }
    handleUserBack(e){
        const { configureTabEvents } = this.props;
        configureTabEvents('showUser',false);
    }
    usersComponent(props) {
        const { assistant } = props;
        let userData = {
            name: '',
            email: '',
        }
        if (assistant.teamIndex !== null && assistant.userIndex !== null) {
            userData = assistant.configureTabData.teams[assistant.teamIndex].users[assistant.userIndex];
        } else if(assistant.locationIndex !== null && assistant.userIndex !== null) {
            userData = assistant.configureTabData.locations[assistant.locationIndex].users[assistant.userIndex];
        }
        return(
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.handleUserFormChange} class="h-100">
                            <div class="modal-body">
                                <div class="location-form-box team-form-box pb-0">
                                    <div class="form-wrap">
                                        <fieldset class="field-set-1">
                                                <label for="company-name" class="form-label float-start">User Name</label>
                                                <div class="input-group mb-3">
                                                    <input id='user-name' required  ref={this.myRef} defaultValue={userData.name} type="text" class="form-control" placeholder="" aria-label="company-name" aria-describedby="company-name"/>
                                                </div>
                                            </fieldset>
                                            <fieldset class="field-set-1">
                                                <label for="company-name" class="form-label float-start">User Email</label>
                                                <div class="input-group mb-3">
                                                    <input id='user-email' required ref={this.myRef} defaultValue={userData.email} type="email" class="form-control" placeholder="" aria-label="company-name" aria-describedby="company-name"/>
                                                </div>
                                            </fieldset>
                                        
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" onClick={this.handleUserBack} class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary">Save changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    render() {
        return this.usersComponent(this.props);
    }
}

export default UsersComponent;
