import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UsersComponent from './users';
import User from "./user";

class LocationsComponent extends Component{
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.handleBack = this.handleBack.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }
    handleFormChange(e) {
        const { assistant } = this.props;
        if (assistant.configureTabData.locations.length > 0 && assistant.locationIndex !== null) {
            assistant.configureTabData.locations[assistant.locationIndex][e.target.getAttribute("data-id")] = e.target.value;
        }
    }

    handleBack(e){
        const { configureTabEvents } = this.props;
        configureTabEvents('showLocation',false);
    }
    locationsComponent(props) {
        const { assistant, message, configureTabEvents, handleUserClick } = props;
        let locationData = {
            name: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            users: [],
        }
        if (assistant.configureTabData.locations.length > 0 && assistant.locationIndex !== null) {
            const selectedLocation = assistant.configureTabData.locations[assistant.locationIndex];
            locationData = selectedLocation;
        } else {
            let index = assistant.configureTabData.locations.length;
            assistant.locationIndex = index;
            assistant.configureTabData.locations[assistant.locationIndex] = locationData;
        }
        const teamClicked = {yes: false, no: true}
        return(
            <div class="tab-pane fade active show" id="leftTab2" role="tabpanel" aria-labelledby="leftTab2-tab">
                <div class="form-box location-form-box">
                    <div class="form-wrap">
                        <form>
                            <fieldset class="field-set-0 text-fieldset">
                                <div class="input-group mb-5">
                                    <div className="back-button-box">
                                        <a onClick={this.handleBack} href="javascript:;"><i className="fa fa-chevron-left"></i></a>
                                    </div>
                                    <h4 class="text-center mb-4">
                                        Add Location
                                    </h4>
                                    <small class="text-center">Let your inbound GPT route notifications to the right person on your team based on your physical locations or offices</small>
                                </div>
                            </fieldset>
                            <fieldset class="field-set-1">
                                <label for="company-name" class="form-label">Location Name</label>
                                <div class="input-group mb-3">
                                    <input id='location-name' data-id = 'name' onChange={this.handleFormChange} ref={this.myRef} defaultValue={locationData.name} type="text" class="form-control" placeholder="" aria-label="company-name" aria-describedby="company-name"/>
                                </div>
                            </fieldset>
                            <fieldset class="field-set-1">
                                <label for="company-name" class="form-label">Address</label>
                                <div class="input-group mb-3">
                                    <input id='location-address' data-id = 'address' onChange={this.handleFormChange} ref={this.myRef} defaultValue={locationData.address} type="text" class="form-control" placeholder="" aria-label="company-name" aria-describedby="company-name"/>
                                </div>
                            </fieldset>
                            <fieldset class="field-set-1">
                                <label for="company-name" class="form-label">City</label>
                                <div class="input-group mb-3">
                                    <input id='location-city' data-id = 'city' onChange={this.handleFormChange} ref={this.myRef} defaultValue={locationData.city} type="text" class="form-control" placeholder="" aria-label="company-name" aria-describedby="company-name"/>
                                </div>
                            </fieldset>
                            <fieldset class="field-set-1">
                                <label for="company-name" class="form-label">State</label>
                                <div class="input-group mb-3">
                                    <input id='location-state' data-id = 'state' onChange={this.handleFormChange} ref={this.myRef} defaultValue={locationData.state} type="text" class="form-control" placeholder="" aria-label="company-name" aria-describedby="company-name"/>
                                </div>
                            </fieldset>
                            <fieldset class="field-set-1">
                                <label for="company-name" class="form-label">Zip Code</label>
                                <div class="input-group mb-3">
                                    <input id='location-zip' data-id = 'zip' onChange={this.handleFormChange} ref={this.myRef} defaultValue={locationData.zip} type="text" class="form-control" placeholder="" aria-label="company-name" aria-describedby="company-name"/>
                                </div>
                            </fieldset>
                            
                            <fieldset class="field-set-4">
                                <h4 class="form-label text-center">Add Users </h4>
                                <small class="text-center d-block w-100 mb-4">Let your Inbound GPT send email notifications to these users</small>
                                { locationData.users ? locationData.users.map((user, index) => (
                                    <User 
                                        key = {index} 
                                        index={index} 
                                        assistant = {assistant}
                                        user = {user}
                                        handleUserClick = {handleUserClick}
                                    />
                                ))
                                :
                                ""
                                }
                                <div class="adduser-box d-flex justify-content-end">
                                    <button class="btn btn-outline-secondary" type="button" id="button-addon1"  data-bs-toggle="modal" data-bs-target="#exampleModal">Add user</button>
                                </div>
                                
                            </fieldset>
                        </form>
                    </div>
                </div>
                <UsersComponent 
                    message = { message}
                    assistant={assistant}
                    teamClicked= { teamClicked }
                    configureTabEvents = {configureTabEvents}
                />
            </div>
        );
    }
    render() {
        return this.locationsComponent(this.props);
    }
}

export default LocationsComponent;
