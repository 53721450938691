
import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RightTabslistItems from "../components/right_tab_list_items";
import RightTabContent from "./right_tab_content";
class RightTabslist extends Component { 
  
  createRightTabslist(props) {
    const { message, assistant, previewTabEvents } = props;
    return (
      <div class="col-md-6 h-100 border-start for-desktop-only pt-2 pb-5 overflow-auto d-flex flex-column">
        <RightTabslistItems message = { message} assistant= {assistant} previewTabEvents = {previewTabEvents}/>
        <RightTabContent  message = { message} assistant= {assistant} previewTabEvents = {previewTabEvents} />
      </div>
    );
  }

  render() {
    return this.createRightTabslist(this.props);
  }
}

export default RightTabslist;