import { fork, takeEvery,takeLatest , select} from 'redux-saga/effects';
let getState = null;
let botProp = null;
let messageProp = null;


const rootSaga = function* rootSaga(params, dispatch) {
  yield fork(handleNewMessage, params);
}


export const handleNewMessage = function* handleNewMessage(params) {
  yield takeEvery('ADD_MESSAGE_CT', (action) => {
    //params.dispatch(resume());
    params.socket.send(JSON.stringify(action));
  });
  yield takeEvery('ADD_MESSAGE_PT', (action) => {
    //params.dispatch(resume());
    params.socket.send(JSON.stringify(action));
  });
  yield takeEvery('UPDATE_INSTRUCTIONS', (action) => {
    //params.dispatch(resume());
    params.socket.send(JSON.stringify(action));
  });
  yield takeEvery('SAVE_CONFIG', (action) => {
    //params.dispatch(resume());
    params.socket.send(JSON.stringify(action));
  });
};



const dispatchToServiceHandler=function* dispatchToServiceHandler (action){
  switch(action.subType){
    default:
      break;
  }
  switch (action.type) {
    default:
      break;
  }
}

export default rootSaga;