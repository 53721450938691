import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class RightTabslistItems extends Component{
  rightTabslistItems(props){
    const { assistant } = props;
    let ptab = ''
    if (assistant.disablePreviewTab) ptab = 'tab';
    return (
      <ul class="nav nav-tabs justify-content-center border-0" id="rightTab" role="tablist">
          <li class="nav-item">
              <a class="nav-link active" id="rightTab1-tab" data-bs-toggle={ptab} href="#rightTab1" role="tab" aria-controls="rightTab1" aria-selected="true">Preview</a>
          </li>
          <li class="nav-item">
              <a class="nav-link" id="rightTab2-tab" data-bs-toggle="" href="#rightTab2" role="tab" aria-controls="rightTab2" aria-selected="false">Embed</a>
          </li>
      </ul>
    );
  }
  render() {
    return this.rightTabslistItems(this.props);
  }
}

export default RightTabslistItems;
