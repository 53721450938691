import { showCreateTab } from "../actions";

const initialState = {
    fetching: false,
    data: null,
    error: false,
    cssTheme: 'default',
    lostConnection: false,
    loadedState:false,
    selectedGpt: 'Inbound GPT Builder',
    selectedFileCT: null,
    uploadSucess: false,
    uploadError: false,
    uploadMsg:'',
    uploaded: false,
    disableConfigureTab: false,
    configureTabData: {
    "company_name": "303 Dental Group",
    "follow_up_person": {
        "name": "Kathy Doe",
        "email": "kathy@siteglue.ai"
    },
    "url": "https://303dentalgroup.com/",
    "inbound_gpt_agent_name": "Lisa",
    "description": "Inbound GPT for 303 Dental Group Business",
    "business_summary": "303 Dental Group is a distinguished dental care provider located in Highlands Ranch, Colorado. The highly qualified team of doctors, including Drs. Roberts and Beilby, create individualized treatment plans for each patient. The practice utilizes state-of-the-art technology, such as digital x-rays and intra-oral cameras, to ensure efficient and effective care. 303 Dental Group offers a wide array of dental treatment options, including general preventive dentistry, periodontics, dental implants and prosthodontics, cosmetic dentistry, and orthodontic treatment with the Invisalign® system. The clinic recently underwent a remodel to make the environment more comfortable and pleasant for patients.",
    "locations": [
        {
            "location_name": "303 Dental Group",
            "address": "300 Plaza Drive STE 102, Highlands Ranch, CO 80129",
            "phone_number": "303-683-3332",
            "contact_name": "Kathy Doe",
            "contact_email_address": "kathy@siteglue.ai",
            "name": "303 Dental Group",
            "users": [
                {
                    "name": "Kathy Doe"
                }
            ]
        }
    ],
    "inbound_gpt_name": "Lisa",
    "icp": "Family-oriented individuals and couples seeking high-quality dental care in the Highlands Ranch area.",
    "sic": "8021 - Offices and Clinics of Dentists",
    "conversation_starters": [
        "How do I schedule an appointment?",
        "What services does 303 Dental Group offer?",
        "How do I pay for my dental care?"
    ],
    "call_to_action_buttons": [
        "Schedule Now",
        "Learn More"
    ],
    "lead_scoring_algorithm": {
        "New_Customer": 80,
        "Existing_Customer": 50,
        "ICP_Match": 20
    },
    "new_customer_qualifying_questions": [
        "Are you seeking a family dentist in Highlands Ranch?",
        "Are you looking for a dentist who offers a wide range of services?",
        "Are you interested in scheduling an appointment at a convenient time?"
    ],
    "icpHtml": "Family-oriented individuals and couples seeking high-quality dental care in the Highlands Ranch area.",
    "lsaHtml": "",
    "teams": [
        {
            "name": "",
            "users": []
        }
    ]
},
    fullWidth: true,
    leadData: {},
    showTeam: false,
    showlocation: false,
    showUser: false,
    showInstruction:  false,
    tab2active: '',
    userIndex: null,
    teamIndex: null,
    locationIndex: null,
    knowledgebase: '',
    disablePreviewTab: false,
    showKb: false,
    showDo: false,
    ct_instruction:'',
    configTab_instruction: '',
    pt_instruction:'',
    en_instruction:'',
    showCompleteBox: false,
    instructionMessage: '',
    loggedIn: false,
    session_token: '',
    login_message: '',
    password_message: '',
    signup_success: '',
    signup_error: '',
    loginModalShown: false,
    loading: false,
    reset_message: '',
    reset_message_success: '',
    user: {},
    showCreateTab: true,
};



const assistant = (state = initialState, action) => {
    if (action.type === 'SOCKET_CLOSED') {
        return { ...state, lostConnection: true,loadedState:false };
    }
    if (action.type === 'RESUME') {
        return { ...state, loadedState:false };
    }
    if (action.type === 'PERSIST') {
        return { ...state, loadedState:true, lostConnection: false};
    }
    if (action.type === 'SOCKETCONCONNECTED') {
        const { ct_instruction, configTab_instruction,pt_instruction,en_instruction} = action.instructionSet;
        return { ...state, loadedState:true, lostConnection: false, ct_instruction, configTab_instruction,pt_instruction,en_instruction};
    }
    switch(action.type) {
        case 'SELECT_ASSITANT':
            return {...state, selectedGpt: action.selectedGpt}
        case 'UPLOAD_SUCESS':
            return {...state, uploadMsg: action.msg,uploadSucess: true,uploadError: false,uploaded: true}
        case 'UPLOAD_ERROR':
            return {...state, uploadMsg: action.msg,uploadSucess: false,uploadError: true}
        case 'CLEAR_UPLOAD_MSG':
            return {...state, uploadMsg: '',uploadSucess: false,uploadError: false}
        case 'SETUPCOMPLETE':
            return {...state, disableConfigureTab: false,configureTabData: action.configureData,showCompleteBox:false }
        case 'ADD_FULL_WIDTH':
            return {...state, fullWidth: true}
        case 'REMOVE_FULL_WIDTH':
            return {...state, fullWidth: false}
        case 'LEADCOMPLETE':
            return {...state,leadData: action.leadData }
        case 'SHOW_LOCATION':
            if (action.show)
                return {...state,showlocation: action.show, locationIndex: action.index, teamIndex: null}
            else
                return {...state,showlocation: action.show, tab2active: 'active show', locationIndex: null, userIndex: null}
        case 'SHOW_TEAM':
            if (action.show)
                return {...state,showTeam: action.show, teamIndex: action.index, locationIndex: null}
            else
                return {...state,showTeam: action.show, tab2active: 'active show', teamIndex: null, userIndex: null}
        case 'SHOW_USER':
                if (action.show)
                    return {...state,showUser: action.show, userIndex: action.index}
                else
                    return {...state,showUser: action.show, tab2active: 'active show',userIndex: null}
        case 'SHOW_INSTRUCTION':
            if(action.show)
                return {...state,showInstruction: action.show}
            else
                return {...state,showInstruction: action.show, tab2active: 'active show'}
        case 'SHOW_DEVELOPER_OPTIONS':
            if(action.show)
                return {...state,showDo: action.show}
            else
                return {...state,showDo: action.show, tab2active: 'active show'}
        case 'SHOW_KNOWLEDGE_BASE':
            if(action.show)
                return {...state,showKb: action.show}
            else
                return {...state,showKb: action.show, tab2active: 'active show'}
        case 'UPDATE_INSTRUCTIONS': 
            return { 
                ...state,
                ct_instruction: action.create_instrucions,
                pt_instruction: action.preview_instructions,
                configTab_instruction: action.config_instructions,
                en_instruction: action.en_instruction
            }
        case 'SAVE_CONFIG':
            return {
                ...state,
                instructionMessage: '',
                showCompleteBox: false,
            }
        case 'SAVE_CONFIG_COMPLETE':
            return {
                ...state,
                instructionMessage: action.msg,
                showCompleteBox: true,
            }
        case 'UPDATE_INSTRUCTIONS_COMPLETE':
            return {
                ...state,
                instructionMessage: action.msg,
                showCompleteBox: true,
            }
        case 'LOGIN':
            return {
                ...state,
                loggedIn: true,
                signup_success: '',
                password_message: '',
                user: action.user,
            }
        case 'LOGOUT':
            return {
                ...state,
                loggedIn: false,
                loginModalShown: false,
                signup_success: '',
                password_message: '',
                signup_error: '',
                user: {},
            }
        case 'LOGIN_FAILED':
            return {
                ...state,
                login_message: action.message,
                loggedIn: false,
                signup_success:'',
                password_message: '',
                signup_error: '',
            }
        case 'PASSWORD_DIFFERENT':
            return {
                ...state,
                password_message: action.message,
                signup_success:'',
                signup_error: '',
            }
        case 'SIGNUP_FAILED':
            return {
                ...state,
                signup_error: action.message,
                password_message: '',
                signup_success:'',
            }
        case 'SIGNUP':
            return {
                ...state,
                signup_success: action.message,
                password_message: '',
                signup_error: '',
            }
        case 'LG_MODAL_SHOWN':
            return {
                ...state,
                loginModalShown: action.loginModalShown
            }
        case 'SPLASH_SCREEN':
            return {
                ...state,
                loading: action.loading
            }
        case 'RESET':
            return {
                ...state,
                reset_message_success: action.msg,
                reset_message: '',
                password_message: '',
            }
        case 'RESET_ERR':
            return {
                ...state,
                reset_message: action.msg,
                reset_message_success: '',
                password_message: '',
            }
        case 'SHOW_CREATE_TAB':
            return {
                ...state,
                showCreateTab: action.show,
            }
        case 'SHOW_PREVIEW_TAB':
            let showPreviewTab = false;
            if (action.show)  showPreviewTab = true;
            return {
                ...state,
                disablePreviewTab: action.show,
            }
        default:
            return state;
    }
}

export default assistant