import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LocationsComponent from "./locations";
import TeamsComponent from "./teams";
import Location from "./location";
import Team from "./team";
import InstructionsComponent from "./instruction";
import KbOptionsComponent from "./knwoledge_base";
import DeveloperOptionsComponent from "./developer_options";

class ConfigureTabContent extends Component{
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.handleLocationClick = this.handleLocationClick.bind(this);
        this.handleTeamClick = this.handleTeamClick.bind(this);
        this.handleUserClick = this.handleUserClick.bind(this);
        this.handleInstructionClick = this.handleInstructionClick.bind(this);
        this.handlekbClick = this.handlekbClick.bind(this);
        this.handleDOClick = this.handleDOClick.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleCtaChange = this.handleCtaChange.bind(this);
        this.handleFaqChange = this.handleFaqChange.bind(this);
        this.handleLqqChange = this.handleLqqChange.bind(this);
        this.removeElement = this.removeElement.bind(this);
        this.saveConfigData = this.saveConfigData.bind(this);
    }
    handleFormChange(e) {
        const { assistant } = this.props;
        assistant.configureTabData[e.target.getAttribute("id")] = e.target.value;
        if (e.target.getAttribute('id') == 'lsaHtml')
            assistant.configureTabData.lead_scoring_algorithm = e.target.value;
    }
    handleCtaChange(e) {
        const { assistant } = this.props;
        let cta1 = (document.getElementById('cta1')) ? document.getElementById('cta1').value : '';
        let cta2 = (document.getElementById('cta2')) ? document.getElementById('cta2').value : '';
        let cta3 = (document.getElementById('cta3')) ? document.getElementById('cta3').value : "";
        if (assistant.configureTabData?.cta_labels) {
            assistant.configureTabData.cta_labels[0] = cta1;
            assistant.configureTabData.cta_labels[1] = cta2;
            assistant.configureTabData.cta_labels[2] = cta3;
        }
        if (assistant.configureTabData?.call_to_action_buttons) {
            assistant.configureTabData.call_to_action_buttons[0] = cta1;
            assistant.configureTabData.call_to_action_buttons[1] = cta2;
            assistant.configureTabData.call_to_action_buttons[2] = cta3;
        }
    }
    handleFaqChange(e) {
        const { assistant } = this.props;
        let faq1 = (document.getElementById('faq1')) ? document.getElementById('faq1').value : '';
        let faq2 = (document.getElementById('faq2')) ? document.getElementById('faq2').value : '';
        let faq3 = (document.getElementById('faq3')) ? document.getElementById('faq3').value : '';
        let faq4 = (document.getElementById('faq4')) ? document.getElementById('faq4').value : '' ;
        let faq5 = (document.getElementById('faq5')) ? document.getElementById('faq5').value : '';
        if (assistant.configureTabData?.faqs) {
            assistant.configureTabData.faqs[0] = faq1;
            assistant.configureTabData.faqs[1] = faq2;
            assistant.configureTabData.faqs[2] = faq3;
            assistant.configureTabData.faqs[3] = faq4;
            assistant.configureTabData.faqs[4] = faq5;
        }
        if (assistant.configureTabData?.conversation_starters) {
            assistant.configureTabData.conversation_starters[0] = faq1;
            assistant.configureTabData.conversation_starters[1] = faq2;
            assistant.configureTabData.conversation_starters[2] = faq3;
            assistant.configureTabData.conversation_starters[3] = faq4;
            assistant.configureTabData.conversation_starters[4] = faq5;
        }
    }
    handleLqqChange(e) {
        const { assistant } = this.props;
        let lqq1 = (document.getElementById('lqq1')) ? document.getElementById('lqq1').value : '';
        let lqq2 = (document.getElementById('lqq2')) ? document.getElementById('lqq2').value : '';
        let lqq3 = (document.getElementById('lqq3')) ? document.getElementById('lqq3').value : '';
        if (assistant.configureTabData?.['new_customer,_client_or_patient_qualifying_question']) {
            assistant.configureTabData['new_customer,_client_or_patient_qualifying_question'][0] = lqq1;
            assistant.configureTabData['new_customer,_client_or_patient_qualifying_question'][1] = lqq2;
            assistant.configureTabData['new_customer,_client_or_patient_qualifying_question'][2] = lqq3;
        }
        if (assistant.configureTabData?.['new_customer,_client_or_patient_qualifying_questions']) {
            assistant.configureTabData['new_customer,_client_or_patient_qualifying_questions'][0] = lqq1;
            assistant.configureTabData['new_customer,_client_or_patient_qualifying_questions'][1] = lqq2;
            assistant.configureTabData['new_customer,_client_or_patient_qualifying_questions'][2] = lqq3;
        }
        if (assistant.configureTabData?.lead_scoring_algorithm?.LSA?.questions) {
            assistant.configureTabData.lead_scoring_algorithm.LSA[0] = lqq1;
            assistant.configureTabData.lead_scoring_algorithm.LSA[1] = lqq2;
            assistant.configureTabData.lead_scoring_algorithm.LSA[2] = lqq3;
        }
        if (assistant.configureTabData?.new_customer_qualifying_questions) {
            assistant.configureTabData.new_customer_qualifying_questions[0] = lqq1;
            assistant.configureTabData.new_customer_qualifying_questions[1] = lqq2;
            assistant.configureTabData.new_customer_qualifying_questions[2] = lqq3;
        }
    }
    removeElement(e) { 
        e.currentTarget.parentElement.remove();
        const { assistant } = this.props;
        const type = e.currentTarget.getAttribute("data-type");
        const index = e.currentTarget.getAttribute("data-index");
        console.log(type)
        console.log(index)
        if (assistant.configureTabData?.faqs && type == 'faq') {
            assistant.configureTabData.faqs.splice(index, 1);
            //delete assistant.configureTabData.faqs[index];

        }
        if (assistant.configureTabData?.conversation_starters && type == 'faq') {
            assistant.configureTabData.conversation_starters.splice(index, 1);
            //delete assistant.configureTabData.conversation_starters[index];
        }
        if (assistant.configureTabData?.cta_labels && type == 'cta') {
            assistant.configureTabData.cta_labels.splice(index, 1);
            //delete  assistant.configureTabData.cta_labels[index]
        }
        if (assistant.configureTabData?.call_to_action_buttons  && type == 'cta') {
            assistant.configureTabData.call_to_action_buttons.splice(index, 1);
            //delete assistant.configureTabData.call_to_action_buttons[index];
        }
        if (assistant.configureTabData?.['new_customer,_client_or_patient_qualifying_question'] && type == 'lqq') {
            assistant.configureTabData['new_customer,_client_or_patient_qualifying_question'].splice(index, 1);
            //delete assistant.configureTabData['new_customer,_client_or_patient_qualifying_question'][index];
        }
        if (assistant.configureTabData?.['new_customer,_client_or_patient_qualifying_questions'] && type == 'lqq') {
            assistant.configureTabData['new_customer,_client_or_patient_qualifying_questions'].splice(index, 1);
            //delete assistant.configureTabData['new_customer,_client_or_patient_qualifying_questions'][index];
        }
        if (assistant.configureTabData?.lead_scoring_algorithm?.LSA?.questions && type == 'lqq') {
            assistant.configureTabData.lead_scoring_algorithm.LSA.splice(index, 1);
            //delete assistant.configureTabData.lead_scoring_algorithm.LSA[index];
        }
        if (assistant.configureTabData?.new_customer_qualifying_questions && type == 'lqq') {
            assistant.configureTabData.new_customer_qualifying_questions.splice(index, 1);
            //delete assistant.configureTabData.new_customer_qualifying_questions[index];
        }
    }
    handleLocationClick(e){
        const { configureTabEvents } = this.props;
        const data = {
            index: e.currentTarget.getAttribute('data-index'),
            toggle: true,
        }
        configureTabEvents('showLocation',data);
    }
    handleTeamClick(e){
        const { configureTabEvents } = this.props;
        const data = {
            index: e.currentTarget.getAttribute('data-index'),
            toggle: true,
        }
        configureTabEvents('showTeam',data);
    }
    handleUserClick(e){
        const { configureTabEvents } = this.props;
        const data = {
            index: e.currentTarget.getAttribute('data-index'),
            toggle: true,
        }
        configureTabEvents('showUser',data);
    }
    handleInstructionClick(e){
        const { configureTabEvents } = this.props;
        configureTabEvents('showInstruction',true);
    }
    handlekbClick(e){
        const { configureTabEvents } = this.props;
        configureTabEvents('showKb',true);
    }
    handleDOClick(e){
        const { configureTabEvents } = this.props;
        configureTabEvents('showDo',true);
    }
    saveConfigData() { 
        const { assistant, configureTabEvents } = this.props;
        const objStr = JSON.stringify(assistant.configureTabData);
        const session = localStorage.getItem('key');
        const data = {
            object: objStr,
            session,
        }
        configureTabEvents('saveConfig', data);
        document.body.style.cursor = 'wait';
    }
    configureTabContent(props) {
        const { assistant,message,configureTabEvents } = props;
        let faq1 = '', faq2= '',faq3 = '',faq4 = '', faq5 ='';
        let cta1 = '', cta2 = '', cta3 = '';
        let lqq1 = '', lqq2 = '', lqq3 = '';
        if(Object.keys(assistant.configureTabData).length > 0) {
            let faqs = [];
            let ctas = [];
            let lqqs = [];
            if (assistant.configureTabData?.faqs) {
                if (typeof assistant.configureTabData.faqs != 'object')
                    faqs = assistant.configureTabData.faqs.split("?");
                faq1 = (undefined !== faqs && undefined != faqs[0]) ? faqs[0]: '';
                faq2 = (undefined !== faqs && undefined != faqs[1]) ? faqs[1]: '';
                faq3 = (undefined !== faqs && undefined != faqs[2]) ? faqs[2]: '';
                faq4 = (undefined !== faqs && undefined != faqs[3]) ? faqs[3] : '';
                faq5 = (undefined !== faqs && undefined != faqs[4]) ? faqs[4]: '';
            }
            if( assistant.configureTabData?.cta_labels ) {
                ctas = assistant.configureTabData.cta_labels.split(",");
                cta1 = (undefined !== ctas && undefined != ctas[0]) ? ctas[0]: '';
                cta2 = (undefined !== ctas && undefined != ctas[1]) ? ctas[1]: '';
                cta3 = (undefined !== ctas && undefined != ctas[2]) ? ctas[2]: '';
            }
            if( assistant.configureTabData?.lqq ) {
                lqqs = assistant.configureTabData.lqq.split("?");
                lqq1 = (undefined !== lqqs && undefined != lqqs[0]) ? lqqs[0]: '';
                lqq2 = (undefined !== lqqs && undefined != lqqs[1]) ? lqqs[1]: '';
                lqq3 = (undefined !== lqqs && undefined != lqqs[2]) ? lqqs[2]: '';
            }
            if (assistant.configureTabData?.conversation_starters) {
                faqs = assistant.configureTabData.conversation_starters;
                faq1 = (undefined !== faqs && undefined != faqs[0]) ? faqs[0]: '';
                faq2 = (undefined !== faqs && undefined != faqs[1]) ? faqs[1]: '';
                faq3 = (undefined !== faqs && undefined != faqs[2]) ? faqs[2]: '';
                faq4 = (undefined !== faqs && undefined != faqs[3]) ? faqs[3] : '';
                faq5 = (undefined !== faqs && undefined != faqs[4]) ? faqs[4] : '';
                faq1 = (undefined !== faqs && undefined != faqs[0] && undefined != faqs[0]['FAQ1']) ? faqs[0]['FAQ1']: faqs[0];
                faq2 = (undefined !== faqs && undefined != faqs[1] && undefined != faqs[1]['FAQ2']) ? faqs[1]['FAQ2']: faqs[1];
                faq3 = (undefined !== faqs && undefined != faqs[2] && undefined != faqs[2]['FAQ3']) ? faqs[2]['FAQ3']: faqs[2];
                faq4 = (undefined !== faqs && undefined != faqs[3] && undefined != faqs[3]['FAQ4']) ? faqs[3]['FAQ4'] : faqs[3];
                faq5 = (undefined !== faqs && undefined != faqs[4] && undefined != faqs[4]['FAQ5']) ? faqs[4]['FAQ5'] : faqs[4];
            }
            if( assistant.configureTabData?.call_to_action_buttons) {
                ctas = assistant.configureTabData.call_to_action_buttons;
                cta1 = (undefined !== ctas && undefined != ctas[0]) ? ctas[0]: '';
                cta2 = (undefined !== ctas && undefined != ctas[1]) ? ctas[1]: '';
                cta3 = (undefined !== ctas && undefined != ctas[2]) ? ctas[2] : '';
                cta1 = (undefined !== ctas && undefined != ctas[0] && undefined != ctas[0]['CTA1']) ? ctas[0]['CTA1']: ctas[0];
                cta2 = (undefined !== ctas && undefined != ctas[1] && undefined != ctas[1]['CTA2']) ? ctas[1]['CTA2']: ctas[1];
                cta3 = (undefined !== ctas && undefined != ctas[2] && undefined != ctas[2]['CTA3']) ? ctas[2]['CTA3']: ctas[2];
            }
            if( assistant.configureTabData?.['new_customer,_client_or_patient_qualifying_question']) {
                lqqs = assistant.configureTabData['new_customer,_client_or_patient_qualifying_question'];
                lqq1 = (undefined !== lqqs && undefined != lqqs[0]) ? lqqs[0]: '';
                lqq2 = (undefined !== lqqs && undefined != lqqs[1]) ? lqqs[1]: '';
                lqq3 = (undefined !== lqqs && undefined != lqqs[2]) ? lqqs[2] : '';
                lqq1 = (undefined !== lqqs && undefined != lqqs[0] && undefined != lqqs[0]['LQQ1']) ? lqqs[0]['LQQ1']: lqqs[0];
                lqq2 = (undefined !== lqqs && undefined != lqqs[1] && undefined != lqqs[1]['LQQ2']) ? lqqs[1]['LQQ2']: lqqs[1];
                lqq3 = (undefined !== lqqs && undefined != lqqs[2] && undefined != lqqs[2]['LQQ3']) ? lqqs[2]['LQQ3']: lqqs[2];
            }
            if( assistant.configureTabData?.['new_customer,_client_or_patient_qualifying_questions']) {
                lqqs = assistant.configureTabData['new_customer,_client_or_patient_qualifying_questions'];
                lqq1 = (undefined !== lqqs && undefined != lqqs[0]) ? lqqs[0]: '';
                lqq2 = (undefined !== lqqs && undefined != lqqs[1]) ? lqqs[1]: '';
                lqq3 = (undefined !== lqqs && undefined != lqqs[2]) ? lqqs[2] : '';
                lqq1 = (undefined !== lqqs && undefined != lqqs[0] && undefined != lqqs[0]['LQQ1']) ? lqqs[0]['LQQ1']: lqqs[0];
                lqq2 = (undefined !== lqqs && undefined != lqqs[1] && undefined != lqqs[1]['LQQ2']) ? lqqs[1]['LQQ2']: lqqs[1];
                lqq3 = (undefined !== lqqs && undefined != lqqs[2] && undefined != lqqs[2]['LQQ3']) ? lqqs[2]['LQQ3']: lqqs[2];
            }
            if( assistant.configureTabData?.['new_customer,_client_or_patient_qualifying_questions']) {
                lqqs = assistant.configureTabData['new_customer,_client_or_patient_qualifying_questions'];
                lqq1 = (undefined !== lqqs && undefined != lqqs[0]) ? lqqs[0]: '';
                lqq2 = (undefined !== lqqs && undefined != lqqs[1]) ? lqqs[1]: '';
                lqq3 = (undefined !== lqqs && undefined != lqqs[2]) ? lqqs[2] : '';
                lqq1 = (undefined !== lqqs && undefined != lqqs[0] && undefined != lqqs[0]['QQ1']) ? lqqs[0]['QQ1']: lqqs[0];
                lqq2 = (undefined !== lqqs && undefined != lqqs[1] && undefined != lqqs[1]['QQ2']) ? lqqs[1]['QQ2']: lqqs[1];
                lqq3 = (undefined !== lqqs && undefined != lqqs[2] && undefined != lqqs[2]['QQ3']) ? lqqs[2]['QQ3']: lqqs[2];
            }
            if (assistant.configureTabData?.lead_scoring_algorithm?.LSA?.questions) { 
                lqqs = assistant.configureTabData.lead_scoring_algorithm.LSA.questions;
                lqq1 = (undefined !== lqqs && undefined != lqqs[0]) ? lqqs[0]: '';
                lqq2 = (undefined !== lqqs && undefined != lqqs[1]) ? lqqs[1]: '';
                lqq3 = (undefined !== lqqs && undefined != lqqs[2]) ? lqqs[2] : '';
            }//new_customer_qualifying_questions
            if( assistant.configureTabData?.new_customer_qualifying_questions) {
                lqqs = assistant.configureTabData.new_customer_qualifying_questions;
                lqq1 = (undefined !== lqqs && undefined != lqqs[0]) ? lqqs[0]: '';
                lqq2 = (undefined !== lqqs && undefined != lqqs[1]) ? lqqs[1]: '';
                lqq3 = (undefined !== lqqs && undefined != lqqs[2]) ? lqqs[2] : '';
                lqq1 = (undefined !== lqqs && undefined != lqqs[0] && undefined != lqqs[0]['LQQ1']) ? lqqs[0]['LQQ1']: lqqs[0];
                lqq2 = (undefined !== lqqs && undefined != lqqs[1] && undefined != lqqs[1]['LQQ2']) ? lqqs[1]['LQQ2']: lqqs[1];
                lqq3 = (undefined !== lqqs && undefined != lqqs[2] && undefined != lqqs[2]['LQQ3']) ? lqqs[2]['LQQ3']: lqqs[2];
            }
        }
        if (assistant.showlocation) {
            return (
                <LocationsComponent 
                    message = { message}
                    assistant= {assistant} 
                    configureTabEvents = {configureTabEvents}
                    handleUserClick = {this.handleUserClick}
                />
            );
        } else if(assistant.showTeam) {
            return (
                <TeamsComponent
                message = { message}
                assistant= {assistant} 
                configureTabEvents = {configureTabEvents}
                handleUserClick = {this.handleUserClick}
                />
            );
        } else if (assistant.showInstruction){
            return (
                <InstructionsComponent 
                    message = { message}
                    assistant= {assistant} 
                    configureTabEvents = {configureTabEvents}
                    handleUserClick = {this.handleUserClick}
                />
            )
        } else if(assistant.showKb) {
            return (
                <KbOptionsComponent
                    message = { message}
                    assistant= {assistant} 
                    configureTabEvents = {configureTabEvents}
                    handleUserClick = {this.handleUserClick}
                />
            );
        } else if (assistant.showDo) {
            return (
                <DeveloperOptionsComponent
                    message = { message}
                    assistant= {assistant} 
                    configureTabEvents = {configureTabEvents}
                    handleUserClick = {this.handleUserClick}
                />
            );
        } else {
            const tab2Name = `tab-pane fade ${assistant.tab2active}`;
            let teams = [];
            let locations = [];
            let business_summary = assistant.configureTabData.business_summary;
            if (assistant.configureTabData.business_summary?.text) business_summary = assistant.configureTabData.business_summary?.text;
            if (assistant.configureTabData.description && ! assistant.configureTabData.business_summary) business_summary = assistant.configureTabData.description;
            if (Array.isArray(assistant.configureTabData.teams) && assistant.configureTabData.teams.length > 0) teams = assistant.configureTabData.teams
            if (Array.isArray(assistant.configureTabData.locations) && assistant.configureTabData.locations.length > 0) locations = assistant.configureTabData.locations
            return(
                <div class={tab2Name} id="leftTab2" role="tabpanel" aria-labelledby="leftTab2-tab">
                    <div class="form-box">
                        <div class="form-wrap">
                            <form>
                                <fieldset class="field-set-0">
                                    <div class="input-group mb-5">
                                        <input type="text" class="form-control" readonly style={{display: "none"}}/>
                                        <label class="input-group-text" for="fileInput">
                                        <i class="fa fa-plus"></i>
                                        </label>
                                        <input type="file" class="form-control" id="fileInput" style={{display: "none"}}/>
                                    </div>
                                </fieldset>
                                <fieldset class="field-set-1">
                                    <label for="company-name" class="form-label">Company Name</label>
                                    <div class="input-group mb-3">
                                        <input id='company_name' onChange={this.handleFormChange} ref={this.myRef} defaultValue={assistant.configureTabData.company_name} type="text" class="form-control" placeholder="" aria-label="company-name" aria-describedby="company-name"/>
                                    </div>
                                </fieldset>
                                <fieldset class="field-set-2">
                                    <label for="copilot-description" class="form-label">Business Description</label>
                                    <div class="input-group mb-3">
                                        <textarea onChange={this.handleFormChange} ref={this.myRef} defaultValue={business_summary} class="form-control" id="business_summary" rows="3"/>
                                    </div>
                                </fieldset>
                                <fieldset class="field-set-2">
                                    <label for="copilot-description" class="form-label">Ideal Customer Profile</label>
                                    <div class="input-group mb-3">
                                        <textarea onChange={this.handleFormChange} ref={this.myRef} defaultValue={assistant.configureTabData.icpHtml} class="form-control" id="icpHtml" rows="3"/>
                                    </div>
                                </fieldset>
                                <fieldset class="field-set-2">
                                    <label for="copilot-description" class="form-label">Lead Scoring Algorithm</label>
                                    <div class="input-group mb-3">
                                        <textarea onChange={this.handleFormChange} ref={this.myRef} defaultValue={assistant.configureTabData.lsaHtml} class="form-control" id="lsaHtml" rows="3"/>
                                    </div>
                                </fieldset>
                                <fieldset class="field-set-3">
                                    <label for="Conversation-text" class="form-label">Conversation Starters</label>
                                    <div class="input-group mb-3">
                                        <input id='faq1' onChange={this.handleFaqChange} ref={this.myRef} defaultValue={faq1} type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="Conversation-text1"/>
                                        <span data-type='faq' data-index="0"  class="input-group-text" id="Conversation-text1" onClick={this.removeElement}><i class="fa fa-times"></i></span>
                                    </div>
                                    <div class="input-group mb-3">
                                        <input id='faq2' onChange={this.handleFaqChange}  ref={this.myRef} defaultValue={faq2} type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="Conversation-text2"/>
                                        <span data-type='faq' data-index="1" class="input-group-text" id="Conversation-text2" onClick={this.removeElement}><i class="fa fa-times"></i></span>
                                    </div>
                                    <div class="input-group mb-3">
                                        <input id='faq3' onChange={this.handleFaqChange} ref={this.myRef} defaultValue={faq3} type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="Conversation-text3"/>
                                        <span data-type='faq' data-index="2" class="input-group-text" id="Conversation-text3" onClick={this.removeElement}><i class="fa fa-times"></i></span>
                                    </div>
                                    <div class="input-group mb-3">
                                        <input id='faq4' onChange={this.handleFaqChange} ref={this.myRef} defaultValue={faq4} type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="Conversation-text4"/>
                                        <span data-type='faq' data-index="3" class="input-group-text" id="Conversation-text4" onClick={this.removeElement}><i class="fa fa-times"></i></span>
                                    </div>
                                    <div class="input-group mb-3">
                                        <input id='faq5'  defaultValue={faq5} onChange={this.handleFaqChange} type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="Conversation-text5"/>
                                        <span data-type='faq' data-index="4" class="input-group-text" id="Conversation-text5" onClick={this.removeElement}><i class="fa fa-times"></i></span>
                                    </div>
                                </fieldset>
                                <fieldset class="field-set-4">
                                    <label for="cta-text" class="form-label">CTA Buttons</label>
                                    <div class="input-group mb-3">
                                        <input id='cta1' onChange={this.handleCtaChange} ref={this.myRef} defaultValue={cta1} type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="cta-text1"/>
                                        <span data-type='cta' data-index="0" class="input-group-text" id="cta-text1" onClick={this.removeElement}><i class="fa fa-times"></i></span>
                                    </div>
                                    <div class="input-group mb-3">
                                        <input id='cta2'  onChange={this.handleCtaChange} ref={this.myRef} defaultValue={cta2} type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="cta-text2"/>
                                        <span data-type='cta' data-index="1" class="input-group-text" id="cta-text2" onClick={this.removeElement}><i class="fa fa-times"></i></span>
                                    </div>
                                    <div class="input-group mb-3">
                                        <input id='cta3'  onChange={this.handleCtaChange} ref={this.myRef} defaultValue={cta3} type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="cta-text3"/>
                                        <span data-type='cta' data-index="2" class="input-group-text" id="cta-text3" onClick={this.removeElement}><i class="fa fa-times"></i></span>
                                    </div>
                                </fieldset>
                                <fieldset class="field-set-4">
                                    <label for="lead-text" class="form-label">Lead Qualifying Questions</label>
                                    <div class="input-group mb-3">
                                        <input id='lqq1' onChange={this.handleLqqChange} ref={this.myRef} defaultValue={lqq1} type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="lead-text1"/>
                                        <span data-type='lqq' data-index="0"   class="input-group-text" id="lead-text1" onClick={this.removeElement}><i class="fa fa-times"></i></span>
                                    </div>
                                    <div class="input-group mb-3">
                                        <input id='lqq2' onChange={this.handleLqqChange} ref={this.myRef} defaultValue={lqq2} type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="lead-text2"/>
                                        <span data-type='lqq' data-index="1" class="input-group-text" id="lead-text2" onClick={this.removeElement}><i class="fa fa-times"></i></span>
                                    </div>
                                    <div class="input-group mb-3">
                                        <input id='lqq3' onChange={this.handleLqqChange} ref={this.myRef} defaultValue={lqq3} type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="lead-text3"/>
                                        <span data-type='lqq' data-index="2" class="input-group-text" id="lead-text3" onClick={this.removeElement}><i class="fa fa-times"></i></span>
                                    </div>
                                </fieldset>
                                <fieldset class="field-set-4">
                                    <label for="lead-text" class="form-label">Locations</label>
                                    { locations.map((location, index) => (
                                        (undefined !== location.errors)?
                                        "": <Location 
                                                key = {index} 
                                                index = { index } 
                                                assistant = {assistant}
                                                location = {location}
                                                handleLocationClick = {this.handleLocationClick}
                                                handleUserClick = {this.handleUserClick}
                                            />
                                    ))}
                                    <button onClick={this.handleLocationClick} class="btn btn-outline-secondary" type="button" id="button-addon1">Create a new location</button>
                                </fieldset>
                                <fieldset class="field-set-5 pt-4">
                                    <label for="lead-text" class="form-label">Teams</label>
                                    { teams.map((team, index) => (
                                        (undefined !== team.errors)?
                                        "": <Team 
                                                key = {index}
                                                index = {index}
                                                assistant = {assistant}
                                                team = {team}
                                                handleTeamClick = {this.handleTeamClick}
                                                handleUserClick = {this.handleUserClick}
                                            />
                                    ))}
                                    <button onClick={this.handleTeamClick} class="btn btn-outline-secondary" type="button" id="button-addon1">Create a new team</button>
                                </fieldset>
                                <fieldset class="field-set-5 pt-4">
                                    <label for="lead-text" class="form-label"></label>
                                    <div class="input-group mb-3">
                                        <button onClick = {this.saveConfigData} type="button" class="btn btn-primary">Save changes</button>
                                    </div>
                                    {
                                            (assistant.showCompleteBox) ? 
                                            <div class="alert alert-success" role="alert">
                                                {assistant.instructionMessage}
                                            </div>
                                            :
                                            ""
                                        }  
                                </fieldset>
                                {/* <fieldset class="field-set-5 pt-4">
                                    <label for="lead-text" class="form-label">Knowledge</label>
                                    <div class="input-group mb-3">
                                        <button onClick={this.handlekbClick} class="btn btn-outline-secondary" type="button" id="button-addon1">Update Knowledge</button>
                                    </div>
                                </fieldset>
                                <fieldset class="field-set-5 pt-4">
                                    <label for="lead-text" class="form-label">Instructions</label>
                                    <div class="input-group mb-3">
                                        <button onClick={this.handleInstructionClick} class="btn btn-outline-secondary" type="button" id="button-addon1">Update Instructions</button>
                                    </div>
                                </fieldset>
                                <fieldset class="field-set-5 pt-4">
                                    <label for="lead-text" class="form-label">Dev options</label>
                                    <div class="input-group mb-3">
                                        <button onClick={this.handleDOClick} class="btn btn-outline-secondary" type="button" id="button-addon1">Update All Instructions</button>
                                    </div>
                                </fieldset> */}
                            </form>
                        </div>
                    </div>
                </div>
            );
        }
    }
    render() {
        return this.configureTabContent(this.props);
    }
}

export default ConfigureTabContent;
