import  "./loading_status.css";
import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
class Loadingstatus extends Component{
    componentDidMount(){
        const { message } = this.props;
        this.setProgress(message.statusPercent)
    }
    componentDidUpdate(){
        const { message } = this.props;
        this.setProgress(message.statusPercent)
    }
    createLoadingstatus(props){
        const { message } = props;
        return (
            <div class="loader-container">
            <svg class="loader" viewBox="0 0 50 50">
              <circle class="loader-background" cx="25" cy="25" r="20" fill="none" stroke="#f3f3f3" stroke-width="5"/>
              <circle class="loader-progress" cx="25" cy="25" r="20" fill="none" stroke="#3498db" stroke-width="5" stroke-linecap="round" transform="rotate(-90 25 25)" stroke-dasharray="0, 125.6"/>
            </svg>
            &nbsp;{message.statusMessage}
          </div>
          
        )
    }
    render() {
        return this.createLoadingstatus(this.props);
    }

    setProgress(percentage) {
        const circumference = 2 * Math.PI * 20; // 2 * π * radius (20 is the radius of the circle)
        const progress = (percentage / 100) * circumference;
        
        const loaderProgress = document.querySelector('.loader-progress');
        loaderProgress.setAttribute('stroke-dasharray', `${progress} ${circumference}`);
    }
}

export default Loadingstatus;