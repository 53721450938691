import React, { Component } from 'react';
//class="custom-button"
const createMarkup = (html) => {
    html = html.replaceAll(/class"quick_reply"/g, 'class="custom-button"');
    html = html.replaceAll(/class="quick_reply"/g, 'class="custom-button"')
    return { __html: html };
};
class Message extends Component {
    message (props) {
        const { message, assistant, messageRec, handleQrbClick } = props;
        let ppicSrc = '/images/co-pilot.png';
        let utitle = assistant.selectedGpt;
        if (messageRec.author === "Me") {
            ppicSrc = 'https://s.gravatar.com/avatar/ef0cdc44e23d4660c432255ff17ae9c4?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fjw.png';
            utitle = 'You';
        }
        return (
            <div class="chat-list-box-wrap row">
                <div class="left-image-box">
                    <img style={{borderRadius: '1.125rem'}} src={ppicSrc} class="logo" alt="paper image" width="41" height="auto"/>
                </div>
                <div class="chat-body-box">
                    <div class="chat-text-box">
                        <div class="title"><strong>{utitle}</strong></div>
                        <div onClick={ handleQrbClick } class="chat-description" dangerouslySetInnerHTML={createMarkup(messageRec.message)}></div>
                    </div>
                </div>
            </div>
        );
    }
    render() {
        return this.message(this.props);
    }
}

export default Message
